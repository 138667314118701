// Composables
import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/store/auth';
import { useSocket } from '@/composables/useSocket';

import Home from '@/views/Home.vue';

// AUTH
import Auth from '@/views/Auth/Auth.vue';
import Login from '@/views/Auth/Login.vue';
// import Register from '@/views/Auth/Register.vue';
import ForgotPassword from '@/views/Auth/ForgotPassword.vue';
import ResetPassword from '@/views/Auth/ResetPassword.vue';
import CompleteRegistration from '@/views/Auth/CompleteRegistration.vue';
import Profile from '@/views/Auth/Profile.vue';
import ChangePassword from '@/views/Auth/ChangePassword.vue';

// USERS
import Users from '@/views/Users/Users.vue';
import User from '@/views/Users/User.vue';
import CreateUser from '@/views/Users/CreateUser.vue';

// QUIZZES
import Quizzes from '@/views/Quizzes/Quizzes.vue';
import Quizz from '@/views/Quizzes/Quizz.vue';
import QuizzQuestions from '@/views/Quizzes/QuizzQuestions.vue';
import QuizzUsers from '@/views/Quizzes/QuizzUsers.vue';
import QuizzFill from '@/views/Quizzes/QuizzFill.vue';
import QuizzDone from '@/views/Quizzes/QuizzDone.vue';
import QuizzInvite from '@/views/Quizzes/Invite.vue';
import QuizzQuestion from '@/views/Quizzes/QuizzQuestion.vue';
import QuizzForm from '@/views/Quizzes/QuizzForm.vue';
import CreateQuizz from '@/views/Quizzes/CreateQuizz.vue';
import CreateQuestions from '@/views/Quizzes/CreateQuestions.vue';

// EVENTS
import Events from '@/views/Events/Events.vue';
import Event from '@/views/Events/Event.vue';
import Attendance from '@/views/Events/Attendance.vue';
import EventInvite from '@/views/Events/Invite.vue';
import EventForm from '@/views/Events/EventForm.vue';

// CHAT
import Chat from '@/views/Chat/Chat.vue';
import StartChat from '@/views/Chat/StartChat.vue';
import Messages from '@/views/Chat/Messages.vue';

const routes = [
    {
        path: '/auth',
        name: 'Auth',
        component: Auth,
        redirect: { name: 'Login' },
        beforeEnter: (to, from, next) => {
            const authStore = useAuthStore();
            if (authStore.isAuthenticated) {
                next({ name: 'Home' });
            } else {
                next();
            }
        },
        children: [
            {
                path: 'login',
                name: 'Login',
                component: Login,
            },
            // {
            //     path: 'register',
            //     name: 'Register',
            //     component: Register,
            // },
            {
                path: 'forgot-password',
                name: 'ForgotPassword',
                component: ForgotPassword,
            },
            {
                path: 'reset-password',
                name: 'ResetPassword',
                component: ResetPassword,
            },
            {
                path: 'complete-registration/:code',
                name: 'CompleteRegistration',
                component: CompleteRegistration,
            }
        ],
        meta: {
            authRequired: false,
        },
    },
    {
        path: '/',
        component: Home,
        name: 'Home',
        meta: {
            authRequired: true,
        },
        children: [
            {
                path: 'profile',
                name: 'Profile',
                component: Profile,
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'profile/change-password',
                component: ChangePassword,
                name: 'ChangePassword',
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'users',
                children: [
                    {
                        path: '',
                        component: Users,
                        name: 'Users',
                        meta: {
                            authRequired: true,
                        },
                    },
                    {
                        path: 'create',
                        component: CreateUser,
                        name: 'CreateUser',
                        meta: {
                            authRequired: true,
                        },
                    },
                    {
                        path: ':id',
                        component: User,
                        name: 'User',
                        meta: {
                            authRequired: true,
                        },
                    },
                ],
            },
            {
                path: 'quizzes',
                children: [
                    {
                        path: '',
                        component: Quizzes,
                        name: 'Quizzes',
                        meta: {
                            authRequired: true,
                        },
                    },
                    {
                        path: 'new',
                        component: CreateQuizz,
                        name: 'CreateQuizz',
                        meta: {
                            authRequired: true,
                        },
                    },
                    {
                        path: 'new/questions',
                        component: CreateQuestions,
                        name: 'CreateQuestions',
                        meta: {
                            authRequired: true,
                        },
                    },
                    {
                        path: ':id',
                        component: Quizz,
                        name: 'Quizz',
                        meta: {
                            authRequired: true,
                        },
                        children: [
                            {
                                path: 'questions',
                                component: QuizzQuestions,
                                name: 'QuizzQuestions',
                                children: [
                                    {
                                        path: ':questionId',
                                        component: QuizzQuestion,
                                        name: 'QuizzQuestion',
                                    }
                                ],
                                meta: {
                                    authRequired: true,
                                },
                            },
                            {
                                path: 'users',
                                component: QuizzUsers,
                                name: 'QuizzUsers',
                                meta: {
                                    authRequired: true,
                                },
                            },
                        ],
                    },
                    {
                        path: ':id/edit',
                        component: QuizzForm,
                        name: 'EditQuizz',
                        meta: {
                            authRequired: true,
                        },
                    },
                    {
                        path: ':id/invite',
                        component: QuizzInvite,
                        name: 'QuizzInvite',
                        meta: {
                            authRequired: true,
                        },
                    },
                    {
                        path: ':id/fill',
                        component: QuizzFill,
                        name: 'QuizzFill',
                        meta: {
                            authRequired: true,
                        },
                    },
                    {
                        path: ':id/done/:doneId',
                        component: QuizzDone,
                        name: 'QuizzDone',
                        meta: {
                            authRequired: true,
                        },
                    }
                ]
            },
            {
                path: 'events',
                children: [
                    {
                        path: '',
                        component: Events,
                        name: 'Events',
                        meta: {
                            authRequired: true,
                        },
                    },
                    {
                        path: 'new',
                        component: EventForm,
                        name: 'CreateEvent',
                        meta: {
                            authRequired: true,
                        },
                    },
                    {
                        path: ':id',
                        component: Event,
                        name: 'Event',
                        meta: {
                            authRequired: true,
                        },
                    },
                    {
                        path: ':id/edit',
                        component: EventForm,
                        name: 'EditEvent',
                        meta: {
                            authRequired: true,
                        },
                    },
                    {
                        path: ':id/attendance',
                        component: Attendance,
                        name: 'Attendance',
                        meta: {
                            authRequired: true,
                        },
                    },
                    {
                        path: ':id/invite',
                        component: EventInvite,
                        name: 'EventInvite',
                        meta: {
                            authRequired: true,
                        },
                    }
                ]
            },
            {
                path: 'chat',
                children: [
                    {
                        path: '',
                        component: Chat,
                        name: 'Chat',
                        meta: {
                            authRequired: true,
                        },
                    },
                    {
                        path: ':id',
                        component: Messages,
                        name: 'Messages',
                        meta: {
                            authRequired: true,
                        },
                    },
                    {
                        path: 'start-chat',
                        component: StartChat,
                        name: 'StartChat',
                        meta: {
                            authRequired: true,
                        },
                    }
                ],
            }
        ],
    },
] as Readonly<RouteRecordRaw[]>;

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const $socket = useSocket();
    const authRequired = to.matched.some((route) => route.meta.authRequired);

    if (authRequired) {
        const authStore = useAuthStore();
        const user = authStore.user;
        if (!authStore.isAuthenticated) {
            next({ name: 'Login' });
        } else {
            // Redirect to Quizzes if user is not admin and tries to access Users
            if (to.path.includes('/users') && to.name !== 'QuizzUsers') {
                if (user?.role?.name !== 'Admin' && user?.role?.name !== 'Tutor') {
                    next({ name: 'Quizzes' });
                }
            }
            // Redirect to Users if user is admin and tries to access Chat
            if (to.path.includes('/chat')) {
                if (user?.role?.name == 'Admin') {
                    next({ name: 'Users' });
                }
            }
            next();
        }
    } else {
        next();
    }

    // leave chat room when leaving chat
    if (from.name === 'Messages' && to.name !== 'Messages') {
        const user = useAuthStore().user

        const userId = user?.id
        const room = parseInt(Array.isArray(from.params.id) ? from.params.id[0] : from.params.id);
        $socket.emit('leaveRoom', { user: userId, room: room });
    }

});

export default router;
