<template>
    <v-app-bar
        location="bottom"
        class="lg:tw-hidden">
        <template v-slot:prepend>
            <router-link :to="{ name: 'Events' }">
                <v-icon class="tw-mr-6 tw-text-2xl 3xs:tw-mr-14 2xs:tw-mr-20 xs:tw-text-4xl sm:tw-mr-40 md:tw-mr-48"> mdi-calendar-multiple </v-icon>
            </router-link>
            <router-link :to="{ name: 'Quizzes' }">
                <v-icon class="tw-mr-6 tw-text-2xl 3xs:tw-mr-14 2xs:tw-mr-20 xs:tw-text-4xl sm:tw-mr-40 md:tw-mr-48"> mdi-help-box-multiple </v-icon>
            </router-link>
            <router-link
                v-if="user?.role?.name !== 'Admin'"
                :to="{ name: 'Chat' }">
                <div class="tw-relative">
                    <v-icon
                        class="tw-mr-6 tw-text-2xl 3xs:tw-mr-14 2xs:tw-mr-20 xs:tw-text-4xl sm:tw-mr-40 md:tw-mr-48"
                        style="position: relative">
                        mdi-chat
                    </v-icon>
                    <div class="tw-absolute tw-left-0 tw-top-0 tw-z-10 tw-ml-4 tw-flex tw-h-4 tw-w-4 tw-justify-center tw-rounded-full tw-bg-primary-200 tw-text-center xs:tw-h-6 xs:tw-w-6">
                        <p class="tw-flex tw-text-xs tw-text-white xs:tw-mt-1">{{ getUnreadMessages }}</p>
                    </div>
                </div>
            </router-link>
            <router-link
                v-if="user?.role?.name == 'Admin'"
                :to="{ name: 'Users' }">
                <v-icon class="tw-mr-6 tw-text-2xl 3xs:tw-mr-14 2xs:tw-mr-20 xs:tw-text-4xl sm:tw-mr-40 md:tw-mr-48"> mdi-account-group </v-icon>
            </router-link>
            <router-link :to="{ name: 'Profile' }">
                <v-icon class="tw-text-2xl xs:tw-text-4xl"> mdi-account </v-icon>
            </router-link>
            <!-- <v-app-bar-nav-icon @click="openSidebar"></v-app-bar-nav-icon> -->
        </template>
    </v-app-bar>
</template>

<script setup lang="ts">
    import { useAppStore } from '../store/app';
    import { useAuthStore } from '../store/auth';
    import { useMessageStore } from '../store/message';
    import { useMessage } from '@/composables/useMessage';
    import { useRoute } from 'vue-router';
    import { useSocket } from '@/composables/useSocket';
    import { ref, computed, onMounted, onUnmounted } from 'vue';
    import axios from '@axios';
    import qs from 'qs';

    const $route = useRoute();
    const $socket = useSocket();

    const authStore = useAuthStore();
    const user = authStore.user;

    const messageStore = useMessageStore();

    const updateLastActivity = ref(null as any);

    const getUnreadMessages = computed(() => {
        return messageStore.unreadMessages;
    });

    /** Set of functions to handle user activity, so we can update the user's last activity */
    const startUpdateLastActivity = () => {
        if (!updateLastActivity.value) {
            updateLastActivity.value = setInterval(() => {
                if (!authStore.getToken) return;
                $socket.emit('updateUserActivity', { userId: user.id });
            }, 5000);
        }
    };

    const stopUpdateLastActivity = () => {
        if (updateLastActivity.value) {
            clearInterval(updateLastActivity.value);
            updateLastActivity.value = null;
        }
    };

    const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
            $socket.connect();
            startUpdateLastActivity();
        } else {
            $socket.disconnect();
            stopUpdateLastActivity();
        }
    };

    // Ativar quando o componente for montado
    onMounted(() => {
        // Verifica o estado inicial
        if (document.visibilityState === 'visible') {
            startUpdateLastActivity();
        }
        // Adiciona o evento de visibilidade
        document.addEventListener('visibilitychange', handleVisibilityChange);
    });

    // Limpar quando o componente for desmontado
    onUnmounted(() => {
        stopUpdateLastActivity();
        document.removeEventListener('visibilitychange', handleVisibilityChange);
    });

    /*************************** */

    $socket.connect();

    $socket.on('newMessage', (data) => {
        const currentRoom = parseInt(Array.isArray($route.params.id) ? $route.params.id[0] : $route.params.id);
        const newMessageRoom = data.data.chatRoom;

        if ($route.name === 'Messages' && currentRoom == newMessageRoom) return;
        else useMessage().fetchUnreadMessages(user.id);
    });
</script>

<style scoped></style>
