<template>
    <v-container
        v-if="isLoading == false"
        class="!tw-mt-10 tw-flex tw-flex-col tw-gap-4"
        fluid>
        <!-- Header -->
        <v-row>
            <v-col class="sm:tw-hidden"></v-col>
            <v-col sm="9"
                ><h1 class="tw-text-2xl tw-font-semibold tw-text-secondary sm:tw-text-left sm:tw-text-3xl">
                    {{ $t('users.title') }}
                </h1>
            </v-col>
            <!-- Create User (Desktop) -->
            <v-col class="tw-hidden tw-text-center sm:tw-block">
                <v-btn
                    :to="{ name: 'CreateUser' }"
                    type="submit"
                    class="bg-primary text-white font-weight-semibold tw-mx-auto tw-w-1/2 tw-py-1.5 tw-text-base sm:tw-w-full"
                    height="auto"
                    size="x-large">
                    {{ $t('users.create') }}
                </v-btn></v-col
            >
            <!-- Create User (Mobile) -->
            <v-col class="tw-text-end sm:tw-hidden">
                <router-link :to="{ name: 'CreateUser' }">
                    <v-icon class="tw-text-3xl tw-text-secondary"> mdi-plus-circle </v-icon>
                </router-link>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                sm="8">
                <v-text-field
                    v-model="search"
                    clearable
                    :label="$t('users.search')"
                    variant="outlined"
                    bg-color="tw-white"></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="4">
                <autocomplete-roles
                    :disabled="user?.role.name == 'Tutor'"
                    density="compact"
                    v-model="role"
                    :label="$t('users.role')"></autocomplete-roles>
            </v-col>
        </v-row>
        <!------------>
        <card-user
            v-if="users.length > 0"
            :users="users"></card-user>
        <div
            v-else
            class="tw-mt-4 tw-flex tw-h-full tw-items-center tw-justify-center tw-gap-x-1 tw-font-semibold">
            <p>{{ $t('users.notFound') }}</p>
            <v-icon class="tw-text-primary">mdi-alert-circle</v-icon>
        </div>
    </v-container>
    <div
        v-if="isLoading"
        class="tw-flex tw-h-full tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
</template>
<script setup lang="ts">
    import cardUser from './CardUser.vue';
    import AutocompleteRoles from '@/views/Users/AutocompleteRoles.vue';
    import { ref, watch } from 'vue';
    import axios from '@axios';
    import qs from 'qs';
    import { ContentType } from '@/contentTypes';
    import { useAlert } from '@/composables/useAlert';
    import { useI18n } from 'vue-i18n';
    import { useAuthStore } from '@/store/auth';

    const $alert = useAlert();
    const $t = useI18n().t;

    const { user } = useAuthStore();

    const role = user?.role.name === 'Admin' ? ref('') : ref('Utente');
    const search = ref('');
    const users = ref<ContentType<'User'>[]>([]);
    const isLoading = ref(true);

    watch([role, search], () => {
        fetchUsers();
    });

    const fetchUsers = async () => {
        const params = {
            filters: {
                role: {
                    $and: [
                        {
                            name: {
                                $not: {
                                    $contains: 'Public',
                                },
                            },
                        },
                        {
                            name: {
                                $contains: role.value,
                            },
                        },
                    ],
                },
                username: {
                    $contains: search.value,
                },
            },
            populate: ['role', 'picture'],
        };
        const query = qs.stringify(params);

        await axios
            .get(`/users?${query}`)
            .then((response) => {
                users.value = response.data;
                isLoading.value = false;
            })
            .catch(() => {
                isLoading.value = false;
                $alert.showAlert({
                    type: 'error',
                    text: `${$t('users.alert.error')}`,
                });
            });
    };

    fetchUsers();
</script>
