<template>
    <v-container class="!tw-mt-10 tw-flex tw-flex-col tw-gap-4">
        <!-- Header -->
        <v-row>
            <v-col sm="9">
                <div class="tw-flex tw-items-center tw-gap-x-4">
                    <router-link
                        to=""
                        @click="goBack">
                        <v-icon class="tw-text-3xl tw-text-primary"> mdi-arrow-left </v-icon>
                    </router-link>
                    <h1 class="tw-text-2xl tw-font-semibold tw-text-secondary sm:tw-text-left sm:tw-text-3xl">
                        {{ $t('users.createUser') }}
                    </h1>
                </div>
            </v-col>
            <!-- Create User (Desktop) -->
            <v-col class="tw-hidden tw-text-center sm:tw-block">
                <v-btn
                    type="submit"
                    class="bg-primary text-white font-weight-semibold tw-mx-auto tw-w-1/2 tw-py-1.5 tw-text-base sm:tw-w-full"
                    height="auto"
                    size="x-large"
                    @click="onSubmit">
                    {{ $t('users.create') }}
                </v-btn></v-col
            >
            <!-- Create User (Mobile) -->
            <v-col
                align-self="center"
                class="tw-text-end sm:tw-hidden">
                <v-icon
                    @click="onSubmit"
                    class="tw-text-3xl tw-text-secondary">
                    mdi-check-circle
                </v-icon>
            </v-col>
        </v-row>
        <!------------>
        <v-form
            ref="form"
            @submit.prevent="onSubmit"
            action="">
            <v-row class="tw-mt-8 tw-flex-col tw-items-center tw-gap-x-10 sm:tw-flex-row">
                <v-col cols="auto">
                    <io-img
                        class="tw-h-60 tw-w-60 tw-rounded-full sm:tw-h-72 sm:tw-w-72"
                        format="thumbnail"
                        :media="user?.picture"
                        content-type="Media"
                        cover></io-img>
                    <v-file-input
                        :loading="isLoadingFile"
                        v-model="image"
                        class="custom-label tw-mt-4 tw-w-60 sm:tw-w-72"
                        density="compact"
                        variant="underlined"
                        prepend-icon=""
                        :label="$t('users.changeProfilePicture')"
                        clearable
                        show-size
                        :hide-details="true"
                        @update:model-value="onImageChanged"
                        accept="image/*">
                    </v-file-input>
                </v-col>
                <v-col
                    cols="12"
                    sm="5">
                    <div v-if="me?.role?.name == 'Admin'">
                        <p class="tw-mb-4">{{ $t('users.role') }}</p>
                        <autocomplete-roles
                            density="compact"
                            v-model="role"></autocomplete-roles>
                    </div>
                    <div v-if="me?.role?.name == 'Tutor'">
                        <p class="tw-mb-4">{{ $t('users.role') }}</p>
                        <autocomplete-roles
                            :disabled="true"
                            density="compact"
                            v-model="role"></autocomplete-roles>
                    </div>
                    <p class="tw-mb-4">{{ $t('users.email') }}</p>
                    <v-text-field
                        :rules="[rules.required, rules.email]"
                        clearable
                        :label="$t('users.email')"
                        variant="outlined"
                        v-model="email"
                        bg-color="tw-white"
                        class="tw-mb-4"></v-text-field>
                    <div v-if="me?.role?.name == 'Admin'">
                        <p
                            v-if="role == 'Tutor'"
                            class="tw-mb-4">
                            {{ $t('users.users') }}
                        </p>
                        <p
                            v-if="role == 'Utente'"
                            class="tw-mb-4">
                            {{ $t('users.tutor') }}
                        </p>
                        <v-select
                            v-if=" role == 'Utente'"
                            bg-color="tw-white"
                            clearable
                            chips
                            item-color="primary"
                            item-title="username"
                            item-value="id"
                            :label="$t('users.tutor')"
                            :items="users"
                            v-model="user.parent"
                            multiple
                            variant="outlined">
                        </v-select>
                        <v-select
                            v-if="role == 'Tutor'"
                            bg-color="tw-white"
                            clearable
                            chips
                            item-color="primary"
                            item-title="username"
                            item-value="id"
                            :label="$t('users.users')"
                            :items="users"
                            v-model="user.children"
                            multiple
                            variant="outlined">
                        </v-select>
                    </div>
                </v-col>
            </v-row>
        </v-form>
        <div
            v-if="isLoading"
            class="tw-fixed tw-inset-0 tw-z-10 tw-flex tw-h-full tw-items-center tw-justify-center">
            <v-progress-circular
                indeterminate
                color="primary"
                model-value="20"></v-progress-circular>
        </div>
    </v-container>
</template>
<script setup lang="ts">
    import ioImg from '@/components/ioImg.vue';
    import AutocompleteRoles from '@/views/Users/AutocompleteRoles.vue';
    import axios from '@axios';
    import qs from 'qs';
    import { ref, watch } from 'vue';
    import { ContentType } from '@/contentTypes';
    import { uploadFiles } from '@/composables/upload';
    import { useRouter } from 'vue-router';
    import { useAlert } from '@/composables/useAlert';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    import useRules from '@/composables/rules';
    import { useI18n } from 'vue-i18n';
    import { useAuthStore } from '@/store/auth';

    const me = useAuthStore().user;

    const $alert = useAlert();

    const $t = useI18n().t;

    const rules = useRules();
    const form = ref<VForm>();

    const isLoadingFile = ref(false);

    const $router = useRouter();

    const user = ref<ContentType<'User'>>({});

    const role = me?.role?.name == 'Admin' ? ref('') : ref('Utente');

    const email = ref('');
    const image = ref<File[]>([]);
    const users = ref<ContentType<'User'>[]>([]);
    const isLoading = ref(false);

    const onImageChanged = async () => {
        if (image?.value?.length == 0) return;
        isLoadingFile.value = true;
        await uploadFiles({
            files: image.value,
            field: 'picture',
        }).then((response) => {
            isLoadingFile.value = false;
            if (response.data.length > 0) user.value.picture = response.data[0];
        });
    };

    const onSubmit = async () => {
        if (!form.value) return;
        form.value.validate();

        form.value.resetValidation();

        const { valid } = await form.value.validate();
        if (!valid) return;

        isLoading.value = true;

        const params = {
            data: {
                role: role.value,
                email: email.value,
                picture: user.value.picture,
                parent: me?.role?.name == 'Tutor' ? me?.id : user.value.parent,
                children: user.value.children,
            },
        };
        await axios
            .post('/auth/local/pre-register', params)
            .then(() => {
                isLoading.value = false;
                $router.push({ name: 'Users' });

                $alert.showAlert({
                    type: 'success',
                    text: `${$t('users.alert.success.create')}`,
                });
            })
            .catch(() => {
                isLoading.value = false;
                $alert.showAlert({
                    type: 'error',
                    text: `${$t('users.alert.error')}`,
                });
            });
    };

    const fetchUsers = async () => {
        try {
            const params = {
                filters: {
                    role: {
                        name: role.value == 'Utente' ? 'Tutor' : 'Utente',
                    },
                    id: {
                        $ne: user.value?.id,
                    },
                },
                populate: ['parent', 'role', 'picture'],
            };
            const query = qs.stringify(params);
            const { data } = await axios.get(`/users?${query}`);
            users.value = data;
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text: `${$t('users.alert.error')}`,
            });
            console.log(error);
        }
    };

    // watch role
    watch(role, () => {
        fetchUsers();
    });

    const goBack = () => {
        $router.go(-1);
    };
</script>
