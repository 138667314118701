<template>
    <div class="text-center">
        <v-dialog
            v-model="dialog"
            max-width="400"
            persistent>
            <v-card
                prepend-icon="mdi-download"
                :title="$t('shared.installAppNotAppearing')"
                :text="$t('shared.installAppInstructions')">
                <template v-slot:actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        class="close-btn text-white font-weight-semibold tw-py-1.5"
                        variant="text"
                        height="auto"
                        elevation="0"
                        @click="handleDialog('close')"
                        >{{ $t('shared.close') }}</v-btn
                    >
                    <v-btn
                        class="bg-primary text-white font-weight-semibold tw-ml-2 tw-py-1.5"
                        variant="flat"
                        height="auto"
                        elevation="0"
                        @click="handleDialog('install')"
                        >{{ $t('shared.install') }}</v-btn
                    >
                </template>
            </v-card>
        </v-dialog>
    </div>
</template>
<script setup lang="ts">
    import { ref } from 'vue';
    import { useAppStore } from '@/store/app';

    const appStore = useAppStore();
    const isInstallerInstructionsVisible = appStore.isInstallerInstructionsVisible;

    const dialog = ref(isInstallerInstructionsVisible);

    const handleDialog = (action: string) => {
        if (action === 'close') {
            dialog.value = false;
        } else if (action === 'install') {
            const userAgent = navigator.userAgent.toLowerCase();
            if (userAgent.includes('android')) {
                window.open('https://support.google.com/chrome/answer/9658361?hl=pt&co=GENIE.Platform%3DAndroid&oco=1#:~:text=Instale%20uma%20app,instru%C3%A7%C3%B5es%20no%20ecr%C3%A3.', '_blank');
            } else if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
                window.open('https://support.apple.com/pt-pt/guide/iphone/iph42ab2f3a7/ios#:~:text=Adicionar%20o%20%C3%ADcone,de%20notifica%C3%A7%C3%A3o.', '_blank');
            } else if (userAgent.includes('chrome')) {
                window.open('https://support.google.com/chrome/answer/9658361?hl=pt&co=GENIE.Platform%3DDesktop&oco=1#:~:text=Instale%20uma%20app,no%20Chrome.', '_blank');
            } else if (userAgent.includes('safari')) {
                window.open('https://support.apple.com/pt-pt/guide/iphone/iph42ab2f3a7/ios#:~:text=Adicionar%20o%20%C3%ADcone,de%20notifica%C3%A7%C3%A3o.', '_blank');
            } else if (userAgent.includes('firefox')) {
                window.open('https://addons.mozilla.org/en-US/firefox/addon/pwas-for-firefox/', '_blank');
            } else if (userAgent.includes('edge')) {
                window.open('https://learn.microsoft.com/pt-pt/microsoft-edge/progressive-web-apps-chromium/ux#:~:text=a%20outros%20aplicativos.-,Instalando%20um%20PWA,um%20PWA%20na%20Microsoft%20Store%2C%20selecione%20Entrar%20na%20p%C3%A1gina%20do%20aplicativo%3A,-Gerenciando%20PWAs', '_blank');
            }

            dialog.value = false;
        }
        //set the dialog to not show again
        appStore.setIsInstallerInstructionsVisible(false);
    };
</script>
<style scoped>
    .v-dialog > .v-overlay__content > .v-card,
    .v-dialog > .v-overlay__content > form > .v-card {
        background-color: rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        color: white;
    }
</style>
