<template>
    <v-container
        id="chat-messages"
        v-if="isLoading == false"
        class="tw-flex tw-h-full tw-flex-col tw-justify-between tw-gap-4"
        fluid>
        <div>
            <!-- Header -->
            <v-row class="tw-sticky tw-top-0 tw-z-10 tw-bg-white">
                <v-col>
                    <div class="tw-flex tw-items-center">
                        <router-link
                            to=""
                            @click="goBack">
                            <v-icon class="tw-text-3xl tw-text-primary"> mdi-arrow-left </v-icon>
                        </router-link>
                        <v-card
                            elevation="0"
                            class="tw-px-0"
                            :title="chatRoom?.users?.find((usr) => usr.id !== user.id)?.username">
                            <template v-slot:subtitle>
                                <p
                                    v-if="isOnline"
                                    class="tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap tw-font-light tw-text-green">
                                    {{ $t('chat.online') }}
                                </p>
                                <p
                                    v-if="!isOnline"
                                    class="tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap tw-font-light">
                                    {{ $t('chat.offline') }}
                                </p>
                            </template>
                            <template v-slot:prepend>
                                <v-avatar size="65">
                                    <io-img
                                        class="2xl:tw-h-16 2xl:tw-w-16"
                                        format="thumbnail"
                                        :media="chatRoom?.users?.find((usr) => usr.id !== user.id)?.picture"
                                        content-type="Media"
                                        width="65"
                                        height="65"
                                        cover></io-img>
                                </v-avatar>
                            </template>
                        </v-card>
                    </div>
                </v-col>
            </v-row>
            <!------------>
            <div
                v-for="(chatMessage, index) of chatMessages"
                :key="chatMessage.id"
                v-intersect="index === 0 ? onIntersect : ''"
                class="tw-mt-4">
                <v-row
                    v-if="chatMessage?.user?.id == user?.id"
                    justify="end">
                    <v-col
                        cols="auto"
                        class="tw-text-right tw-text-sm tw-text-secondary">
                        <div class="tw-rounded-lg tw-border tw-bg-primary tw-p-2 tw-text-white">
                            <p class="tw-mb-1 tw-text-start tw-text-xs tw-font-light">{{ new Date(chatMessage?.createdAt).toLocaleTimeString('pt-PT', { hour: '2-digit', minute: '2-digit' }) }}</p>
                            <p class="tw-break-words">{{ chatMessage?.message }}</p>
                        </div>
                        <p
                            v-if="chatMessage.chatRead?.wasRead == true"
                            class="tw-text-xs">
                            {{ $t('chat.seenAt') }} {{ new Date(chatMessage?.chatRead?.updatedAt).toLocaleTimeString('pt-PT', { hour: '2-digit', minute: '2-digit' }) }}
                            <v-icon
                                size="x-small"
                                class="tw-text-green"
                                >mdi-check-circle</v-icon
                            >
                        </p>
                    </v-col>
                </v-row>
                <v-row
                    v-else
                    justify="start">
                    <v-col
                        cols="auto"
                        class="tw-text-left tw-text-sm tw-text-secondary">
                        <div class="tw-mb-2 tw-rounded-lg tw-border tw-border-gray-600 tw-p-3">
                            <p class="tw-break-words">{{ chatMessage?.message }}</p>
                        </div>
                        <p class="tw-font-light">{{ new Date(chatMessage?.createdAt).toLocaleTimeString('pt-PT', { hour: '2-digit', minute: '2-digit' }) }}</p>
                    </v-col>
                </v-row>
            </div>
        </div>
        <div class="tw-sticky tw-bottom-0 tw-z-10 tw-bg-white">
            <v-row>
                <v-col>
                    <v-textarea
                        class="tw-rounded-lg"
                        variant="outlined"
                        v-model="message"
                        rows="1"
                        auto-grow
                        :placeholder="$t('chat.write')"></v-textarea>
                </v-col>
                <v-col
                    md="1"
                    cols="2"
                    class="tw-flex tw-items-center tw-justify-center">
                    <v-icon
                        @click.prevent="sendMessage(isWriting)"
                        class="tw-text-3xl tw-text-primary">
                        {{ isWriting ? 'mdi-send' : 'mdi-thumb-up' }}
                    </v-icon>
                </v-col>
            </v-row>
        </div>
    </v-container>
    <div
        v-if="isLoading"
        class="tw-flex tw-h-full tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
</template>
<script setup lang="ts">
    import ioImg from '@/components/ioImg.vue';
    import { useRouter, useRoute } from 'vue-router';
    import { useAuthStore } from '@/store/auth';
    import { useMessageStore } from '@/store/message';
    import { useMessage } from '@/composables/useMessage';
    import { useAlert } from '@/composables/useAlert';
    import { useSocket } from '@/composables/useSocket';
    import { useI18n } from 'vue-i18n';
    import { ContentType } from '@/contentTypes';
    import { nextTick } from 'vue';
    import { ref, watch, computed } from 'vue';
    import axios from '@axios';
    import qs from 'qs';

    const authStore = useAuthStore();
    const user = authStore.user;

    const $socket = useSocket();
    const $alert = useAlert();
    const $t = useI18n().t;

    const page = ref(1);
    const pageSize = ref(25);

    const $route = useRoute();
    const $router = useRouter();
    const message = ref('');
    const isWriting = ref(false);
    const chatRoom = ref<ContentType<'ChatRoom'>>({});
    const chatMessages = ref<ContentType<'ChatMessage'>[]>([]);
    const online = ref(false);

    const isLoading = ref(true);

    const hasMoreMessages = ref(true);

    //scroll to bottom
    const scrollToBottom = () => {
        const container = document.getElementById('chat-messages');
        if (container) {
            window.scrollTo(0, container.scrollHeight);
        }
    };

    //socket on message (receive message)
    $socket.on('message', (data) => {
        chatMessages.value.push({
            messageId: data.messageId,
            message: data.data.message,
            createdAt: data.data.createdAt,
            user: {
                id: data.data.user,
            },
        });
        if (chatRoom.value.id === data.data.chatRoom && user.id !== data.data.user) {
            setTimeout(() => {
                $socket.emit('readMessages', {
                    room: chatRoom.value.id,
                    user: user.id,
                });
            }, 1000);
        }
        nextTick(() => {
            scrollToBottom();
        });
    });

    //to mark message as read, so the user can see if the other user read the message
    $socket.on('wasRead', (data) => {
        let foundChatMessage = chatMessages.value.find((chatMessage) => chatMessage.messageId == data.messageId);
        if (foundChatMessage) {
            foundChatMessage.chatRead = {};
            foundChatMessage.chatRead.wasRead = true;
            foundChatMessage.chatRead.updatedAt = data.data.updatedAt;
        }
    });

    //check if user is online
    $socket.on('isOnline', (isOnline) => {
        if (isOnline) online.value = true;
        if (!isOnline) online.value = false;
    });

    //get messages
    const fetchMessages = async () => {
        try {
            const params = {
                filters: {
                    chatRoom: {
                        id: $route.params.id,
                    },
                },
                pagination: {
                    pageSize: pageSize.value,
                    page: page.value,
                },
                sort: ['createdAt:DESC'],
                populate: ['chatRoom', 'chatRead', 'user.picture'],
            };
            const query = qs.stringify(params);
            const { data } = await axios.get(`/chat-messages?${query}`);

            if (data.meta.pagination.pageCount == page.value) {
                hasMoreMessages.value = false;
            }

            const messages = data.data.reverse();
            if (page.value === 1) chatMessages.value = messages;
            else chatMessages.value = [...messages, ...chatMessages.value];

            $socket.emit('isOnline', { userId: chatRoom?.value?.users?.find((usr) => usr.id !== user.id)?.id });

            // read all messages
            $socket.emit('readMessages', {
                room: chatRoom.value.id,
                user: user.id,
            });

            $socket.emit('join', { user: user.id, room: chatRoom.value.id });

            // then fetch unread messages, to update the unread messages count
            useMessage().fetchUnreadMessages(user.id);

            isLoading.value = false;
        } catch (error) {
            console.log(error);
            isLoading.value = false;

            $alert.showAlert({
                type: 'error',
                text: `${$t('chat.alert.error')}`,
            });
        }
    };

    //get chat rooms
    const fetchChatRoom = async () => {
        try {
            const params = {
                populate: ['users.picture'],
            };
            const query = qs.stringify(params);
            await axios.get(`/chat-rooms/${$route.params.id}?${query}`).then((response) => {
                chatRoom.value = response.data.data;
                fetchMessages().then(() => {
                    nextTick(() => {
                        scrollToBottom();
                    });
                });
            });
        } catch (error) {
            console.log(error);
            isLoading.value = false;

            $alert.showAlert({
                type: 'error',
                text: `${$t('chat.alert.error')}`,
            });
        }
    };

    fetchChatRoom();

    document.addEventListener('visibilitychange', () => {
        if (!document.hidden) {
            //force load messages
            page.value = 1;
            fetchMessages().then(() => {
                nextTick(() => {
                    scrollToBottom();
                });
            });
        }
    });

    //isOnline is a computed property to check if the user is online or offline
    const isOnline = computed(() => {
        return online.value;
    });

    //socket send message
    const sendMessage = async (isWriting: boolean) => {
        const data = {
            message: isWriting ? message.value : '👍',
            user: user.id,
            room: chatRoom.value.id,
        };
        $socket.emit('sendMessage', data);
        message.value = '';
    };

    // check if user is online every 5 seconds
    setInterval(() => {
        $socket.emit('isOnline', { userId: chatRoom?.value?.users?.find((usr) => usr.id !== user.id)?.id });
    }, 5000);

    // watch if user is writing
    watch(message, (val) => {
        if (val === '') {
            isWriting.value = false;
        } else {
            isWriting.value = true;
        }
    });

    // Handle scroll, to load more messages
    const onIntersect = (entries: IntersectionObserverEntry[]) => {
        if (entries && hasMoreMessages.value) {
            page.value++;
            fetchMessages();
        }
    };

    const goBack = () => {
        $socket.emit('leaveRoom', { user: user.id, room: chatRoom.value.id });
        $router.go(-1);
    };
</script>
