<template>
    <!-- Desktop -->
    <v-row
        v-if="currentIndex > 0"
        class="tw-hidden sm:tw-block">
        <div class="tw-flex">
            <v-col sm="7">
                <div class="tw-flex tw-items-center tw-gap-x-4">
                    <h1 class="tw-text-left tw-text-3xl tw-font-semibold tw-text-secondary">{{ $t('quizzes.form.createQuestions') }}</h1>
                </div>
            </v-col>
            <v-row>
                <v-col v-if="currentIndex > 0">
                    <v-btn
                        @click.prevent="previous"
                        type="submit"
                        class="bg-primary text-white font-weight-semibold tw-mx-auto tw-mt-3 tw-flex tw-w-full tw-items-center tw-justify-center tw-py-1.5 tw-text-base 3xs:tw-mb-2"
                        height="auto"
                        size="x-large">
                        {{ $t('quizzes.form.previous') }}
                    </v-btn>
                </v-col>
                <v-col v-if="currentIndex < quizz.questionsCount">
                    <v-btn
                        @click.prevent="next"
                        type="submit"
                        class="bg-primary text-white font-weight-semibold tw-mx-auto tw-mt-3 tw-flex tw-w-full tw-items-center tw-justify-center tw-py-1.5 tw-text-base 3xs:tw-mb-2"
                        height="auto"
                        size="x-large">
                        {{ $t('quizzes.form.nextt') }}
                    </v-btn>
                </v-col>
                <!-- Finish Button -->
                <v-col v-if="currentIndex == quizz.questionsCount">
                    <v-btn
                        @click.prevent="onSubmit"
                        type="submit"
                        class="bg-primary text-white font-weight-semibold tw-mx-auto tw-mt-3 tw-flex tw-w-full tw-items-center tw-justify-center tw-py-1.5 tw-text-base 3xs:tw-mb-2"
                        height="auto"
                        size="x-large">
                        {{ $t('quizzes.form.finish') }}
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </v-row>
    <v-row class="sm:tw-hidden">
        <v-col>
            <h1 class="tw-text-center tw-text-3xl tw-font-semibold tw-text-secondary sm:tw-text-left">{{ $t('quizzes.form.createQuestions') }}</h1>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <p class="tw-text-xl tw-font-semibold tw-text-secondary">{{ props.currentIndex }}/{{ quizz.questionsCount }}</p>
        </v-col>
        <v-col cols="12">
            <io-img
                class="tw-h-60 sm:tw-h-72"
                format="thumbnail"
                :media="quizz?.picture"
                content-type="Media"
                contain></io-img>
        </v-col>
        <v-col>
            <v-form
                ref="form"
                @submit.prevent="onSubmit"
                action="">
                <v-row>
                    <div
                        v-if="isLoading"
                        class="tw-mx-auto tw-flex tw-items-center tw-justify-center">
                        <v-progress-circular
                            indeterminate
                            color="primary"
                            model-value="20"></v-progress-circular>
                    </div>
                    <!-- Question -->
                    <v-col
                        cols="12"
                        md="12">
                        <p class="tw-mb-2">{{ $t('quizzes.form.question') }}</p>
                        <v-text-field
                            :rules="[rules.required]"
                            clearable
                            variant="outlined"
                            bg-color="tw-white"
                            v-model="quizz.questions[currentIndex - 1].description"
                            :label="$t('quizzes.form.question')"
                            required></v-text-field>
                    </v-col>
                    <!-- Score -->
                    <v-col
                        cols="12"
                        md="12">
                        <p class="tw-mb-2">{{ $t('quizzes.form.score') }}</p>
                        <v-text-field
                            min="0"
                            type="number"
                            clearable
                            variant="outlined"
                            bg-color="tw-white"
                            v-model="quizz.questions[currentIndex - 1].score"
                            :label="$t('quizzes.form.score')"
                            required></v-text-field>
                    </v-col>
                    <!-- Answers Count -->
                    <v-col
                        v-if="
                            quizz?.questions[currentIndex - 1].type != 'Verdadeiro ou Falso' &&
                            quizz?.questions[currentIndex - 1].type != 'Texto Livre' &&
                            quizz?.questions[currentIndex - 1].type != 'Reflexão' &&
                            quizz?.questions[currentIndex - 1].type != 'Semáforo' &&
                            quizz?.questions[currentIndex - 1].type != 'Raivómetro'
                        "
                        cols="12"
                        md="12">
                        <p class="tw-mb-2 tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap">{{ $t('quizzes.form.answersCount') }}</p>
                        <v-text-field
                            min="1"
                            type="number"
                            :rules="[rules.required]"
                            clearable
                            variant="outlined"
                            bg-color="tw-white"
                            v-model="quizz.questions[currentIndex - 1].answersCount"
                            :label="$t('quizzes.form.answersCount')"
                            required
                            @change=""></v-text-field>
                    </v-col>
                    <!-- Answer Type -->
                    <v-col
                        cols="12"
                        xs="6">
                        <p class="tw-mb-2 tw-text-base tw-text-secondary">{{ $t('quizzes.form.answerType') }}</p>
                        <autocomplete-questions-type
                            :rules="[rules.required]"
                            density="compact"
                            v-model="quizz.questions[currentIndex - 1].type"></autocomplete-questions-type>
                    </v-col>
                    <!-- Answers -->
                    <!-- Cards -->
                    <v-col
                        v-if="quizz?.questions[currentIndex - 1].answersCount && quizz?.questions[currentIndex - 1].type == 'Escolha Múltipla'"
                        cols="12">
                        <p class="tw-mb-2 tw-text-lg tw-font-semibold tw-text-secondary">{{ $t('quizzes.form.answers') }}</p>
                        <v-row v-for="(n, i) in parseInt(quizz?.questions[currentIndex - 1].answersCount)">
                            <v-col
                                cols="10"
                                xs="10"
                                sm="11"
                                xl="11"
                                xxl="11">
                                <v-text-field
                                    :rules="[rules.required]"
                                    clearable
                                    bg-color="tw-bg-white"
                                    v-model="quizz.questions[currentIndex - 1].answers[i].name"
                                    :label="$t('quizzes.form.answer') + ' ' + (i + 1)"
                                    required></v-text-field>
                            </v-col>
                            <v-col
                                cols="2"
                                xs="2"
                                sm="1"
                                xl="1"
                                xxl="1">
                                <div
                                    @click="selectCorrectAnswer(i)"
                                    class="tw-flex tw-h-10 tw-items-center tw-justify-center tw-rounded-lg tw-border tw-border-gray-600"
                                    :class="{ 'tw-bg-primary': quizz?.questions[currentIndex - 1].answers[i].isCorrect == true }">
                                    <v-icon
                                        class="tw-text-white"
                                        v-if="quizz?.questions[currentIndex - 1].answers[i].isCorrect == true"
                                        >mdi-check-bold</v-icon
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- True/False -->
                    <v-col
                        v-if="quizz?.questions[currentIndex - 1].answersCount && quizz?.questions[currentIndex - 1].type == 'Verdadeiro ou Falso'"
                        cols="12">
                        <p class="tw-mb-2 tw-text-lg tw-font-semibold tw-text-secondary">{{ $t('quizzes.form.answers') }}</p>
                        <v-row v-for="(n, i) in parseInt(quizz?.questions[currentIndex - 1].answersCount)">
                            <v-col
                                cols="10"
                                xs="10"
                                sm="11"
                                xl="11"
                                xxl="11">
                                <v-text-field
                                    :rules="[rules.required]"
                                    clearable
                                    bg-color="tw-bg-white"
                                    v-model="quizz.questions[currentIndex - 1].answers[i].name"
                                    :label="i == 0 ? $t('quizzes.form.true') : $t('quizzes.form.false')"
                                    required></v-text-field>
                            </v-col>
                            <v-col
                                cols="2"
                                xs="2"
                                sm="1"
                                xl="1"
                                xxl="1">
                                <div
                                    @click="selectCorrectAnswer(i)"
                                    class="tw-flex tw-h-10 tw-items-center tw-justify-center tw-rounded-lg tw-border tw-border-gray-600"
                                    :class="{ 'tw-bg-primary': quizz?.questions[currentIndex - 1].answers[i].isCorrect == true }">
                                    <v-icon
                                        class="tw-text-white"
                                        v-if="quizz?.questions[currentIndex - 1].answers[i].isCorrect == true"
                                        >mdi-check-bold</v-icon
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Open Text -->
                    <!-- Images -->
                    <v-col v-if="quizz?.questions[currentIndex - 1].answersCount && quizz?.questions[currentIndex - 1].type == 'Imagens'">
                        <p class="tw-mb-2 tw-text-lg tw-font-semibold tw-text-secondary">{{ $t('quizzes.form.answers') }}</p>
                        <v-row>
                            <v-col
                                v-for="(n, i) in parseInt(quizz?.questions[currentIndex - 1].answersCount)"
                                cols="6"
                                class="tw-relative">
                                <io-img
                                    :rules="[rules.required]"
                                    class=""
                                    format="original"
                                    height="95"
                                    :media="quizz.questions[currentIndex - 1].answers[i].media"
                                    content-type="Media"
                                    contain></io-img>
                                <v-file-input
                                    :loading="isLoadingFile"
                                    :rules="[rules.required]"
                                    v-model="media[i]"
                                    class="custom-label"
                                    density="compact"
                                    prepend-icon=""
                                    variant="underlined"
                                    :label="$t('quizzes.form.selectImage')"
                                    :clearable="false"
                                    hide-details
                                    accept="image/*"
                                    @update:model-value="onMediaChanged(i)">
                                </v-file-input>
                                <div
                                    @click="selectCorrectAnswer(i)"
                                    class="tw-absolute tw-right-4 tw-top-4 tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-lg tw-border tw-border-gray-600"
                                    :class="{ 'tw-bg-primary': quizz?.questions[currentIndex - 1].answers[i].isCorrect == true }">
                                    <v-icon
                                        class="tw-text-white"
                                        v-if="quizz?.questions[currentIndex - 1].answers[i].isCorrect == true"
                                        >mdi-check-bold</v-icon
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Reflection -->
                    <v-col v-if="quizz?.questions[currentIndex - 1].type == 'Reflexão'">
                        <v-row>
                            <v-col cols="12">
                                <video
                                    v-if="quizz?.questions[currentIndex - 1]?.media?.mime?.includes('video')"
                                    class="tw-mx-auto tw-h-60 tw-w-60"
                                    controls>
                                    <source
                                        :src="API_URL + quizz.questions[currentIndex - 1].media.url"
                                        type="video/mp4" />
                                </video>
                                <io-img
                                    v-else
                                    format="original"
                                    class="tw-mx-auto tw-h-60 tw-w-60"
                                    :media="quizz.questions[currentIndex - 1].media"
                                    content-type="Media"
                                    cover></io-img>
                                <v-file-input
                                    :loading="isLoadingFile"
                                    v-model="media[0]"
                                    density="compact"
                                    variant="underlined"
                                    :label="$t('quizzes.form.loadFile')"
                                    clearable
                                    show-size
                                    :hide-details="true"
                                    @update:model-value="onMediaChanged(0)">
                                </v-file-input>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Traffic Light -->
                    <v-col v-if="quizz?.questions[currentIndex - 1].type == 'Semáforo'">
                        <v-row v-for="(n, i) in 3">
                            <v-col
                                cols="10"
                                xs="10"
                                sm="11"
                                xl="11"
                                xxl="11">
                                <v-text-field
                                    :rules="[rules.required]"
                                    clearable
                                    bg-color="tw-bg-white"
                                    v-model="quizz.questions[currentIndex - 1].answers[i].name"
                                    :label="$t('quizzes.form.answer') + ' ' + (i + 1)"
                                    required></v-text-field>
                            </v-col>
                            <v-col
                                cols="2"
                                xs="2"
                                sm="1"
                                xl="1"
                                xxl="1">
                                <div
                                    @click="selectCorrectAnswer(i)"
                                    class="tw-flex tw-h-10 tw-items-center tw-justify-center tw-rounded-lg tw-border tw-border-gray-600"
                                    :class="{ 'tw-bg-primary': quizz?.questions[currentIndex - 1].answers[i].isCorrect == true }">
                                    <v-icon
                                        class="tw-text-white"
                                        v-if="quizz?.questions[currentIndex - 1].answers[i].isCorrect == true"
                                        >mdi-check-bold</v-icon
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Raivometer -->
                    <v-col v-if="quizz?.questions[currentIndex - 1].type == 'Raivómetro'">
                        <v-row>
                            <v-col cols="12">
                                <video
                                    v-if="quizz?.questions[currentIndex - 1]?.media?.mime?.includes('video')"
                                    class="tw-mx-auto tw-h-60 tw-w-60"
                                    controls>
                                    <source
                                        :src="API_URL + quizz.questions[currentIndex - 1].media.url"
                                        type="video/mp4" />
                                </video>
                                <io-img
                                    v-else
                                    format="original"
                                    class="tw-mx-auto tw-h-60 tw-w-60"
                                    :media="quizz.questions[currentIndex - 1].media"
                                    content-type="Media"
                                    cover></io-img>
                                <v-file-input
                                    :loading="isLoadingFile"
                                    v-model="media[0]"
                                    density="compact"
                                    variant="underlined"
                                    :label="$t('quizzes.form.loadFile')"
                                    clearable
                                    show-size
                                    :hide-details="true"
                                    @update:model-value="onMediaChanged(0)">
                                </v-file-input>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Matching -->
                    <v-col
                        v-if="quizz?.questions[currentIndex - 1].answersCount && quizz?.questions[currentIndex - 1].type == 'Matching'"
                        cols="12">
                        <p class="tw-mb-2 tw-text-lg tw-font-semibold tw-text-secondary">{{ $t('quizzes.form.answers') }}</p>
                        <v-row v-for="(n, i) in quizz?.questions[currentIndex - 1].answers[0].matching">
                            <v-col cols="6">
                                <v-text-field
                                    :rules="[rules.required]"
                                    clearable
                                    bg-color="tw-bg-white"
                                    v-model="quizz.questions[currentIndex - 1].answers[0].matching[i].answer"
                                    :label="$t('quizzes.form.answer') + ' ' + (i + 1)"
                                    required></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    :rules="[rules.required]"
                                    clearable
                                    bg-color="tw-bg-white"
                                    v-model="quizz.questions[currentIndex - 1].answers[0].matching[i].matching"
                                    :label="$t('quizzes.form.matching') + ' ' + (i + 1)"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <!-- Create Quizz (Mobile) -->
                <v-row>
                    <v-col v-if="currentIndex > 0">
                        <v-btn
                            @click.prevent="previous"
                            type="submit"
                            class="bg-primary text-white font-weight-semibold tw-mx-auto tw-mt-3 tw-flex tw-w-full tw-items-center tw-justify-center tw-py-1.5 tw-text-base 3xs:tw-mb-2 sm:tw-hidden"
                            height="auto"
                            size="x-large">
                            {{ $t('quizzes.form.previous') }}
                        </v-btn>
                    </v-col>
                    <v-col v-if="currentIndex < quizz.questionsCount">
                        <v-btn
                            @click.prevent="next"
                            type="submit"
                            class="bg-primary text-white font-weight-semibold tw-mx-auto tw-mt-3 tw-flex tw-w-full tw-items-center tw-justify-center tw-py-1.5 tw-text-base 3xs:tw-mb-2 sm:tw-hidden"
                            height="auto"
                            size="x-large">
                            {{ $t('quizzes.form.nextt') }}
                        </v-btn>
                    </v-col>
                    <!-- Finish Button -->
                    <v-col v-if="currentIndex == quizz.questionsCount">
                        <v-btn
                            @click.prevent="onSubmit"
                            type="submit"
                            class="bg-primary text-white font-weight-semibold tw-mx-auto tw-mt-3 tw-flex tw-w-full tw-items-center tw-justify-center tw-py-1.5 tw-text-base 3xs:tw-mb-2 sm:tw-hidden"
                            height="auto"
                            size="x-large">
                            {{ $t('quizzes.form.finish') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
    </v-row>
</template>
<script setup lang="ts">
    import ioImg from '@/components/ioImg.vue';
    import AutocompleteQuestionsType from './AutocompleteQuestionsType.vue';
    import axios from '@axios';
    import { useRouter } from 'vue-router';
    import { uploadFiles } from '@/composables/upload';
    import { useDict } from '@composables/useDict';
    import { ref, watch } from 'vue';
    import { useAuthStore } from '@/store/auth';
    import { useAlert } from '@/composables/useAlert';
    import { useI18n } from 'vue-i18n';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    import useRules from '@/composables/rules';

    const API_URL = import.meta.env.VITE_API_URL;

    const $alert = useAlert();

    const $t = useI18n().t;

    const rules = useRules();
    const form = ref<VForm>();

    const authStore = useAuthStore();
    const me = authStore.user;

    const props = defineProps(['quizz', 'currentIndex']);
    const emits = defineEmits(['update:quizz', 'next', 'previous']);
    const $router = useRouter();

    const media = ref<File[]>([]);

    const isLoading = ref(false);
    const isLoadingFile = ref(false);

    const indexChanged = ref(false);

    //watch currentIndex change
    watch(
        () => props.currentIndex,
        (newValue, oldValue) => {
            if (newValue != oldValue) {
                indexChanged.value = true;
            }
        },
    );

    //watch answer count and answer type at the same time
    watch(
        () => {
            return {
                type: props.quizz.questions[props.currentIndex - 1].type,
                answersCount: props.quizz.questions[props.currentIndex - 1].answersCount,
            };
        },
        ({ type, answersCount }, { type: oldType, answersCount: oldAnswersCount }) => {
            if (type != oldType && answersCount == oldAnswersCount && indexChanged.value == false) {
                if (type == 'Texto Livre' || type == 'Reflexão' || type == 'Raivómetro') {
                    props.quizz.questions[props.currentIndex - 1].answersCount = 1;
                    props.quizz.questions[props.currentIndex - 1].answers = [
                        {
                            name: '',
                            isCorrect: null,
                            media: null,
                            matching: [],
                        },
                    ];
                }
                if (type == 'Semáforo') {
                    props.quizz.questions[props.currentIndex - 1].answersCount = 3;
                    props.quizz.questions[props.currentIndex - 1].answers = [
                        {
                            name: 'Vermelho',
                            isCorrect: null,
                            media: null,
                            matching: [],
                        },
                        {
                            name: 'Amarelo',
                            isCorrect: null,
                            media: null,
                            matching: [],
                        },
                        {
                            name: 'Verde',
                            isCorrect: null,
                            media: null,
                            matching: [],
                        },
                    ];
                }
                if (type == 'Verdadeiro ou Falso') {
                    props.quizz.questions[props.currentIndex - 1].answersCount = 2;
                    props.quizz.questions[props.currentIndex - 1].answers = [
                        {
                            name: 'Verdadeiro',
                            isCorrect: null,
                            media: null,
                            matching: [],
                        },
                        {
                            name: 'Falso',
                            isCorrect: null,
                            media: null,
                            matching: [],
                        },
                    ];
                }
                //if type is Matching, isCorrect is always true
                // if (type == 'Matching') {
                //     for (let i = 0; i < props.quizz.questions[props.currentIndex - 1].answers.length; i++) {
                //         props.quizz.questions[props.currentIndex - 1].answers[i].isCorrect = true;
                //     }
                // }
            } else if (type == oldType && answersCount != oldAnswersCount && indexChanged.value == false) {
                if (type == 'Imagens' || type == 'Escolha Múltipla') {
                    props.quizz.questions[props.currentIndex - 1].answers = [];
                    for (let i = 0; i < parseInt(answersCount); i++) {
                        props.quizz.questions[props.currentIndex - 1].answers.push({
                            name: '',
                            isCorrect: null,
                            media: null,
                            matching: '',
                        });
                    }
                }
                if (type == 'Matching') {
                    props.quizz.questions[props.currentIndex - 1].answers = [{ name: '', isCorrect: null, media: null, matching: [] }];

                    for (let i = 0; i < parseInt(answersCount); i++) {
                        props.quizz.questions[props.currentIndex - 1].answers[0].matching.push({
                            answer: '',
                            matching: '',
                        });
                    }
                }
            } else if (type != oldType && answersCount != oldAnswersCount && indexChanged.value == false) {
                if (type == 'Texto Livre' || type == 'Reflexão' || type == 'Raivómetro') {
                    props.quizz.questions[props.currentIndex - 1].answersCount = 1;
                    props.quizz.questions[props.currentIndex - 1].answers = [
                        {
                            name: '',
                            isCorrect: null,
                            media: null,
                            matching: [],
                        },
                    ];
                }
                if (type == 'Semáforo') {
                    props.quizz.questions[props.currentIndex - 1].answersCount = 3;
                    props.quizz.questions[props.currentIndex - 1].answers = [
                        {
                            name: 'Vermelho',
                            isCorrect: null,
                            media: null,
                            matching: [],
                        },
                        {
                            name: 'Amarelo',
                            isCorrect: null,
                            media: null,
                            matching: [],
                        },
                        {
                            name: 'Verde',
                            isCorrect: null,
                            media: null,
                            matching: [],
                        },
                    ];
                }
                if (type == 'Verdadeiro ou Falso') {
                    props.quizz.questions[props.currentIndex - 1].answersCount = 2;
                    props.quizz.questions[props.currentIndex - 1].answers = [
                        {
                            name: 'Verdadeiro',
                            isCorrect: null,
                            media: null,
                            matching: [],
                        },
                        {
                            name: 'Falso',
                            isCorrect: null,
                            media: null,
                            matching: [],
                        },
                    ];
                }
                //if type is Matching, isCorrect is always true
                // if (type == 'Matching') {
                //     for (let i = 0; i < props.quizz.questions[props.currentIndex - 1].answers.length; i++) {
                //         props.quizz.questions[props.currentIndex - 1].answers[i].isCorrect = true;
                //     }
                // }
                if (type == 'Imagens' || type == 'Escolha Múltipla') {
                    props.quizz.questions[props.currentIndex - 1].answers = [];
                    for (let i = 0; i < parseInt(answersCount); i++) {
                        props.quizz.questions[props.currentIndex - 1].answers.push({
                            name: '',
                            isCorrect: null,
                            media: null,
                            matching: [],
                        });
                    }
                }
                if (type == 'Matching') {
                    props.quizz.questions[props.currentIndex - 1].answers = [{ name: '', isCorrect: null, media: null, matching: [] }];

                    for (let i = 0; i < parseInt(answersCount); i++) {
                        props.quizz.questions[props.currentIndex - 1].answers[0].matching.push({
                            answer: '',
                            matching: '',
                        });
                    }
                }
            } else if (type != oldType || (answersCount != oldAnswersCount && indexChanged.value == true)) {
                indexChanged.value = false;
            }
        },
    );

    const selectCorrectAnswer = (index: number) => {
        //check if there is already a correct answer
        if (props.quizz.questions[props.currentIndex - 1].answers.find((answer: any) => answer.isCorrect == true)) {
            //if there is, set it to false
            props.quizz.questions[props.currentIndex - 1].answers.find((answer: any) => answer.isCorrect == true).isCorrect = false;
        }
        //set the selected answer to true and others to false
        props.quizz.questions[props.currentIndex - 1].answers[index].isCorrect = !props.quizz.questions[props.currentIndex - 1].answers[index].isCorrect;

        for (let i = 0; i < props.quizz.questions[props.currentIndex - 1].answers.length; i++) {
            if (i != index) {
                props.quizz.questions[props.currentIndex - 1].answers[i].isCorrect = false;
            }
        }
    };

    const onMediaChanged = async (index: number) => {
        if (media?.value[index].length == 0) return;
        isLoadingFile.value = true;
        await uploadFiles({
            files: media.value[index],
            field: 'media',
        }).then((response) => {
            isLoadingFile.value = false;
            if (response.data.length > 0) {
                if (props.quizz.questions[props.currentIndex - 1].type == 'Reflexão' || props.quizz.questions[props.currentIndex - 1].type == 'Raivómetro') {
                    props.quizz.questions[props.currentIndex - 1].media = response.data[0];
                } else props.quizz.questions[props.currentIndex - 1].answers[index].media = response.data[0];
                media.value[index] = [];
            }
        });
    };

    const next = () => {
        if (props.quizz.questions.find((question: any) => question.index === props.currentIndex)) {
            props.quizz.questions[props.currentIndex - 1] = props.quizz.questions[props.currentIndex - 1];
        } else {
            props.quizz.questions.push({
                index: props.currentIndex,
                name: '',
                description: '',
                score: null,
                answersCount: null,
                type: 'Escolha Múltipla',
                answers: [
                    {
                        name: '',
                        isCorrect: null,
                        media: null,
                        matching: [],
                    },
                ],
            });
        }
        emits('next');
    };
    const previous = () => {
        emits('previous');
    };

    const createQuizz = async (questionsIds: any) => {
        if (props.quizz.time) parseInt(props.quizz.time);

        await axios
            .post('/quizzes', {
                data: { ...props.quizz, questions: questionsIds, createdByUser: me.id },
            })
            .then(() => {
                isLoading.value = false;
                $router.push({ name: 'Quizzes' });

                $alert.showAlert({
                    type: 'success',
                    text: `${$t('quizzes.alert.success.create')}`,
                });
            })
            .catch((error) => {
                $alert.showAlert({
                    type: 'error',
                    text: `${$t('quizzes.alert.error')}`,
                });
                console.error(error);
            });
    };

    const createQuestions = async () => {
        // First Create Questions And Answers
        let questionsIds = [] as any[];
        for (let question of props.quizz.questions) {
            let answersIds = [];
            useDict()
                .types(question.type)
                .then((response) => {
                    question.type = response;
                })
                .catch((error) => {
                    $alert.showAlert({
                        type: 'error',
                        text: `${$t('quizzes.alert.success.create')}`,
                    });
                    console.error(error);
                });
            for (let answer of question.answers) {
                const response = await axios.post('/answers', {
                    data: {
                        ...answer,
                    },
                });
                answersIds.push(response.data.data.id);
            }
            question.answers = answersIds;
            await axios
                .post('/questions', {
                    data: { ...question, name: `Pergunta ${!question?.index ? 1 : question.index + 1}` },
                })
                .then((response) => {
                    questionsIds.push(response.data.data.id);
                })
                .catch((error) => {
                    $alert.showAlert({
                        type: 'error',
                        text: `${$t('quizzes.alert.error')}`,
                    });
                    console.error(error);
                });
        }
        // Then Create Quizz Itself
        createQuizz(questionsIds);
    };

    const onSubmit = async () => {
        if (!form.value) return;
        form.value.validate();

        form.value.resetValidation();
        if (props.quizz.questions[props.currentIndex - 1].score == null) {
            props.quizz.questions[props.currentIndex - 1].score = 0;
        }

        const { valid } = await form.value.validate();
        if (!valid) return;

        isLoading.value = true;
        try {
            await createQuestions();
        } catch (error) {
            console.error(error);
        }
    };
</script>
