<template>
    <main class="tw-flex tw-h-full tw-flex-shrink-0 tw-flex-grow tw-flex-col lg:tw-flex-row">
        <!-- Mobile -->
        <div class="tw-flex tw-h-full tw-items-center tw-justify-end tw-bg-white lg:tw-hidden">
            <img
                class="tw-h-full tw-w-full tw-bg-red-logo tw-object-contain"
                src="@/assets/images/auth/logo.svg"
                alt="" />
        </div>
        <!------------->
        <div class="tw-z-10 tw-flex tw-w-full tw-shrink-0 tw-flex-col tw-items-center tw-justify-end tw-gap-8 tw-bg-white tw-p-4 md:tw-gap-12 lg:tw-max-w-lg xl:tw-max-w-3xl">
            <router-view />
            <div class="tw-flex">
                <img
                    class="tw-h-10 tw-object-cover 3xs:tw-h-12"
                    src="@/assets/images/dgrsp_colorido.png"
                    alt="" />
                <img
                    class="tw-h-10 tw-object-cover 3xs:tw-h-12"
                    src="@/assets/images/auth/cipsi_cor.svg"
                    alt="" />
            </div>
        </div>
        <!-- Desktop -->
        <div class="tw-hidden tw-w-full tw-items-center tw-justify-center tw-bg-white lg:tw-block">
            <img
                class="tw-h-full tw-w-full tw-bg-red-logo tw-object-contain"
                src="../../assets/images/auth/logo.svg"
                alt="" />
        </div>
        <!------------->
    </main>
</template>

<script setup lang="ts"></script>
