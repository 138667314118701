import { useAuthStore } from '@/store/auth';
import { Socket, io } from 'socket.io-client';

let socket: Socket | null = null;

export function useSocket() {
    const authStore = useAuthStore();
    if (!socket)
        socket = io(import.meta.env.VITE_WS_URL, {
            closeOnBeforeunload: true,
            withCredentials: true,
            auth: {
                token: authStore.getToken,
            },
        });
    return socket;
}
