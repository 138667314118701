import { useMessageStore } from '@/store/message';
import { useRoute, useRouter } from 'vue-router';
import axios from '@axios';
import qs from 'qs';

export const useMessage = () => {
    const messageStore = useMessageStore();

    async function fetchUnreadMessages(userId: number) {
        try {
            const params = {
                filters: {
                    user: {
                        id: {
                            $eq: userId,
                        },
                    },
                    wasRead: false,
                },
                populate: ['user.picture'],
            };
            const query = qs.stringify(params);
            await axios.get(`/chat-reads?${query}`).then((response) => {
                messageStore.setUnreadMessages(response.data.meta.pagination.total);
            });
        } catch (error) {
            console.error(error);
        }
    };

    return {
        fetchUnreadMessages,
    };
};
