<template>
    <v-container
        class="!tw-mt-10 tw-flex tw-flex-col tw-gap-4"
        v-if="isLoading == false">
        <!-- Header -->
        <v-row class="tw-items-center tw-justify-between">
            <v-col>
                <router-link
                    to=""
                    @click="goBack">
                    <v-icon class="tw-text-3xl tw-text-primary"> mdi-arrow-left </v-icon>
                </router-link>
            </v-col>
            <v-col>
                <h1 class="tw-text-center tw-text-2xl tw-font-semibold tw-text-secondary sm:tw-text-3xl">{{ $t('events.invite') }}</h1>
            </v-col>
            <v-col> </v-col>
        </v-row>
        <!------------>
        <p class="tw-mt-6 tw-font-light tw-text-secondary">{{ $t('events.users') }}</p>
        <v-text-field
            class="tw-mb-2"
            v-model="search"
            clearable
            :label="$t('events.search')"
            variant="outlined"
            bg-color="tw-white"></v-text-field>
        <router-link
            v-for="user of users"
            :to="me?.role?.name == 'Admin' ? { name: 'User', params: { id: user?.id } } : {}">
            <v-card
                elevation="2"
                class="mx-auto tw-h-24 tw-px-0 tw-py-1 sm:tw-px-10"
                :title="user?.username">
                <template v-slot:prepend>
                    <v-avatar size="65">
                        <io-img
                            class="2xl:tw-h-16 2xl:tw-w-16"
                            format="thumbnail"
                            :media="user?.picture"
                            content-type="Media"
                            width="65"
                            height="65"
                            cover></io-img>
                    </v-avatar>
                </template>
                <template v-slot:append>
                    <div class="tw-flex tw-flex-wrap tw-items-center">
                        <v-icon
                            class="tw-text-3xl tw-text-secondary"
                            @click.prevent="inviteUser(user?.id)">
                            mdi-plus-circle
                        </v-icon>
                    </div>
                </template>
            </v-card>
        </router-link>
        <div
            v-if="users.length == 0"
            class="tw-mt-4 tw-flex tw-h-full tw-items-center tw-justify-center tw-gap-x-1 tw-font-semibold">
            <p>{{ $t('events.usersToInviteNotFound') }}</p>
            <v-icon class="tw-text-primary">mdi-alert-circle</v-icon>
        </div>
    </v-container>
    <div
        v-if="isLoading"
        class="tw-flex tw-h-full tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
</template>
<script setup lang="ts">
    import ioImg from '@/components/ioImg.vue';
    import axios from '@axios';
    import qs from 'qs';
    import { ref, watch } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { useAlert } from '@/composables/useAlert';
    import { useI18n } from 'vue-i18n';
    import { ContentType } from '@/contentTypes';
    import { useAuthStore } from '@/store/auth';

    const $alert = useAlert();
    const $t = useI18n().t;

    const useStore = useAuthStore();
    const me = useStore.user;

    const $route = useRoute();
    const $router = useRouter();
    const search = ref('');
    const users = ref<ContentType<'User'>[]>([]);
    const isLoading = ref(true);

    watch([search], () => {
        fetchEventAttendances();
    });
    const fetchEventAttendances = async () => {
        try {
            const params = {
                filters: {
                    event: $route.params.id,
                },
                search: search.value,
                invite: true,
                populate: ['event', 'user.picture'],
            };
            const query = qs.stringify(params);
            await axios
                .get(`/event-attendances?${query}`)
                .then((response) => {
                    users.value = response.data.data;
                })
                .finally(() => {
                    isLoading.value = false;
                });
        } catch (error) {
            console.error(error);
            isLoading.value = false;

            $alert.showAlert({
                type: 'error',
                text: `${$t('events.alert.error')}`,
            });
        }
    };

    fetchEventAttendances();

    const inviteUser = async (id: string) => {
        isLoading.value = true;
        try {
            const params = {
                data: {
                    event: $route.params.id,
                    user: id,
                },
            };
            await axios.post(`/event-attendances`, params).then(() => {
                $alert.showAlert({
                    type: 'success',
                    text: `${$t('events.alert.success.createInvite')}`,
                });
                fetchEventAttendances();
            });
        } catch (error) {
            console.log(error);
            isLoading.value = false;

            $alert.showAlert({
                type: 'error',
                text: `${$t('events.alert.error')}`,
            });
        }
    };

    const goBack = () => {
        $router.go(-1);
    };
</script>
