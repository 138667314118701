// Utilities
import { defineStore } from 'pinia';

export const useMessageStore = defineStore('message', {
    state: () => ({
        unreadMessages: 0,
    }),
    getters: {
        getUnreadMessages(): number {
            return this.unreadMessages;
        },
    },
    actions: {
        setUnreadMessages(value: number) {
            this.unreadMessages = value;
        },
    },
    persist: true,
});
