<template>
    <v-container
        class="!tw-mt-10 tw-flex tw-flex-col tw-gap-4"
        v-if="isLoading == false">
        <!-- Header -->
        <v-row class="tw-mb-6 tw-items-center tw-justify-between">
            <v-col>
                <router-link
                    to=""
                    @click="goBack">
                    <v-icon class="tw-text-3xl tw-text-primary"> mdi-arrow-left </v-icon>
                </router-link>
            </v-col>
            <v-col>
                <h1 class="tw-text-center tw-text-2xl tw-font-semibold tw-text-secondary sm:tw-text-3xl">{{ $t('events.guests') }}</h1>
            </v-col>
            <v-col class="tw-flex tw-justify-end">
                <router-link :to="{ name: 'EventInvite' }">
                    <v-icon class="tw-text-3xl tw-text-secondary"> mdi-plus-circle </v-icon>
                </router-link>
            </v-col>
        </v-row>
        <!------------>
        <router-link
            v-for="eventAttendance in eventAttendances"
            :to="user?.role?.name == 'Admin' ? { name: 'User', params: { id: eventAttendance?.user?.id } } : {}">
            <v-card
                elevation="2"
                class="mx-auto tw-h-28 tw-px-0 tw-py-4 sm:tw-px-10"
                :title="eventAttendance?.user?.username">
                <template v-slot:prepend>
                    <v-avatar size="65">
                        <io-img
                            class="2xl:tw-h-16 2xl:tw-w-16"
                            format="thumbnail"
                            :media="eventAttendance?.user?.picture"
                            content-type="Media"
                            width="65"
                            height="65"
                            cover></io-img>
                    </v-avatar>
                </template>
                <template v-slot:append>
                    <v-icon
                        @click.prevent="removeAttendance(eventAttendance?.id)"
                        class="tw-absolute tw-right-0 tw-top-0 tw-p-4 tw-text-3xl tw-text-red"
                        >mdi-minus-circle</v-icon
                    >
                    <div v-if="eventAttendance?.status == null">
                        <v-icon
                            v-if="eventAttendance?.status == null"
                            class="tw-mr-3 tw-text-3xl tw-text-green"
                            @click.prevent="handleAttendance(true, eventAttendance?.id)">
                            mdi-calendar-check
                        </v-icon>
                        <v-icon
                            class="tw-text-3xl tw-text-red"
                            @click.prevent="handleAttendance(false, eventAttendance?.id)">
                            mdi-calendar-remove
                        </v-icon>
                    </div>
                    <div v-if="eventAttendance?.status == false">
                        <v-chip
                            color="red"
                            closable
                            @click:close.prevent="handleAttendance(null, eventAttendance?.id)">
                            {{ $t('events.missed') }}
                        </v-chip>
                    </div>
                    <div
                        class="tw-flex tw-gap-x-1"
                        v-if="eventAttendance?.status == true">
                        <v-chip
                            closable
                            color="green"
                            v-if="eventAttendance?.status == true"
                            @click:close.prevent="handleAttendance(null, eventAttendance?.id)">
                            {{ $t('events.present') }}
                        </v-chip>
                    </div>
                </template>
            </v-card>
        </router-link>
        <div
            v-if="eventAttendances.length == 0"
            class="tw-mt-4 tw-flex tw-h-full tw-items-center tw-justify-center tw-gap-x-1 tw-font-semibold">
            <p>{{ $t('events.eventAttendancesNotFound') }}</p>
            <v-icon class="tw-text-primary">mdi-alert-circle</v-icon>
        </div>
    </v-container>
    <div
        v-if="isLoading"
        class="tw-flex tw-h-full tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
</template>
<script setup lang="ts">
    import ioImg from '@/components/ioImg.vue';
    import axios from '@axios';
    import qs from 'qs';
    import { ContentType } from '@/contentTypes';
    import { ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useAuthStore } from '@/store/auth';
    import { useAlert } from '@/composables/useAlert';
    import { useI18n } from 'vue-i18n';
    import { template } from 'lodash';

    const $alert = useAlert();
    const $t = useI18n().t;

    const useStore = useAuthStore();
    const user = useStore.user;

    const $route = useRoute();
    const $router = useRouter();
    const isLoading = ref(true);
    const eventAttendances = ref<ContentType<'EventAttendance'>[]>([]);

    const fetchEventAttendances = async () => {
        const params = {
            filters: {
                event: {
                    id: {
                        $eq: $route.params.id,
                    },
                },
            },
            sort: {
                status: 'asc',
            },
            populate: ['event', 'user.picture'],
        };
        const query = qs.stringify(params);
        await axios
            .get(`/event-attendances?${query}`)
            .then(({ data }) => {
                eventAttendances.value = data.data;
                isLoading.value = false;
            })
            .catch((error) => {
                console.log(error);
                isLoading.value = false;

                $alert.showAlert({
                    type: 'error',
                    text: `${$t('events.alert.error')}`,
                });
            });
    };

    fetchEventAttendances();

    //this function has the purpose handle user attendance
    const handleAttendance = async (status: boolean, id: number) => {
        isLoading.value = true;
        try {
            const params = {
                data: {
                    status: status,
                },
            };
            await axios.put(`/event-attendances/${id}`, params).then(() => {
                fetchEventAttendances();
            });
        } catch (error) {
            console.log(error);
            isLoading.value = false;

            $alert.showAlert({
                type: 'error',
                text: `${$t('events.alert.error')}`,
            });
        }
    };

    const removeAttendance = async (id: number) => {
        isLoading.value = true;
        try {
            await axios.delete(`/event-attendances/${id}`).then(() => {
                $alert.showAlert({
                    type: 'success',
                    text: `${$t('events.alert.success.deleteInvite')}`,
                });

                fetchEventAttendances();
            });
        } catch (error) {
            console.log(error);
            isLoading.value = false;

            $alert.showAlert({
                type: 'success',
                text: `${$t('events.alert.error')}`,
            });
        }
    };

    const goBack = () => {
        $router.go(-1);
    };
</script>
