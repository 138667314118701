<!-- usar v-navigation-drawer do lado direito, em mobile vai ficar o ecra todo -->
<template>
    <div
        v-if="isLoading == false && question && usersAnswers.length > 0"
        class="tw-p-1">
        <v-card
            v-for="userAnswer in usersAnswers"
            elevation="0"
            class="mx-auto tw-py-4 lg:tw-py-0"
            :title="userAnswer?.user?.username"
            :subtitle="$t('quizzes.attempt') + userAnswer?.attempt">
            <template v-slot:text>
                <p v-if="userAnswer?.answer?.name"><p class="tw-font-semibold">{{ $t('quizzes.answer') }}</p> {{ userAnswer?.answer?.name }}</p>
                        <!-- Open Text -->
                        <p v-if="userAnswer?.openText"><p class="tw-font-semibold">{{ $t('quizzes.answer') }}</p> {{ userAnswer?.openText }}</p>
                        <!-- Raivometer -->
                        <div v-if="userAnswer?.raivometer" class="tw-flex tw-flex-col">
                             <p class="tw-font-semibold">{{ $t('quizzes.answer') }}</p>
                            <div class="tw-flex tw-items-center tw-gap-x-2">
                                <p>{{ userAnswer?.raivometer }}</p>
                                <v-icon
                            :class="{
    'tw-text-green': parseFloat(userAnswer?.raivometer) <= 25,
    'tw-text-yellow': parseFloat(userAnswer?.raivometer) > 25 && parseFloat(userAnswer?.raivometer) <= 75,
    'tw-text-red': parseFloat(userAnswer?.raivometer) > 75
}">
                                {{ parseFloat(userAnswer?.raivometer) <= 25 ? 'mdi-emoticon-happy' : parseFloat(userAnswer?.raivometer) > 25 && parseFloat(userAnswer?.raivometer) <= 75 ? 'mdi-emoticon-neutral' : 'mdi-emoticon-angry'}}
                            </v-icon>
                            </div>
                        </div>
                        <!-- Matching -->
                        <v-row  v-if="userAnswer?.matching" align="center" no-gutters>
                            <p class="tw-font-semibold">{{ $t('quizzes.answer') }}</p>
                            <div class="tw-flex tw-w-full" v-for="matching in userAnswer?.matching">
                        <v-col v-if="matching.answer" class="tw-px-0" cols="6">
                            <p>{{ matching.answer }}</p>
                        </v-col>
                        <v-col v-if="matching.matching" class="text-right tw-px-0" cols="6">
                            <p>{{ matching.matching }}</p>
                        </v-col>
                            </div>
                        </v-row>
                        <p v-if="userAnswer?.answer?.media"
                            >
                            <p class="tw-font-semibold">{{ $t('quizzes.answer') }}</p><io-img
                            height="150"
                            width="150"
                                format="original"
                                :media="userAnswer?.answer?.media"
                                content-type="Media"
                                cover></io-img
                        ></p>
            </template>
            <template v-slot:prepend>
                <v-avatar size="65">
                    <io-img
                        class="2xl:tw-h-16 2xl:tw-w-16"
                        format="thumbnail"
                        :media="userAnswer?.user?.picture"
                        content-type="Media"
                        width="65"
                        height="65"
                        cover></io-img>
                </v-avatar>
            </template>
            <template v-slot:append>
                <v-icon
                    v-if="userAnswer?.answer?.question?.type == 'Matching' && userAnswer?.matching[userAnswer?.matching.length - 1].isCorrect == true"
                    class="tw-text-green">
                    mdi-check-circle
                </v-icon>
                <v-icon
                    v-if="userAnswer?.answer?.question?.type == 'Matching' && userAnswer?.matching[userAnswer?.matching.length - 1].isCorrect == false"
                    class="tw-text-red">
                    mdi-close-circle
                </v-icon>
                <v-icon
                    v-if="userAnswer?.answer?.question?.type != 'Matching' && userAnswer?.answer?.isCorrect == true"
                    class="tw-text-green">
                    mdi-check-circle
                </v-icon>
                <v-icon
                    v-if="userAnswer?.answer?.question?.type != 'Matching' && userAnswer?.answer?.isCorrect == false"
                    class="tw-text-red">
                    mdi-close-circle
                </v-icon>
                <v-icon v-if="userAnswer?.answer?.question?.type != 'Matching' && userAnswer?.answer?.isCorrect == null"></v-icon>
            </template>
            <v-divider></v-divider>
        </v-card>
        <div class="text-center">
    <v-pagination
    :total-visible="totalVisible"
    color="primary"
      v-model="page"
      :length="pageCount"
      @update:model-value="fetchUsersAnswers"
      rounded="circle"
    ></v-pagination>
  </div>
    </div>
    <div v-if="isLoading == false && question && usersAnswers.length == 0" class="tw-flex tw-h-full tw-items-center tw-justify-center tw-gap-x-1 tw-font-semibold">
        <p>{{ $t('quizzes.answersNotFound') }}</p>
            <v-icon class="tw-text-primary">mdi-alert-circle</v-icon>
    </div>
    <div
        v-if="isLoading"
        class="tw-flex tw-h-full tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
</template>
<script setup lang="ts">
    import { ref, watch, computed, onBeforeMount } from 'vue';
    import { useDisplay } from 'vuetify';
    import { useRouter, useRoute } from 'vue-router';
    import { useAlert } from '@/composables/useAlert';
    import { useI18n } from 'vue-i18n';
    import { ContentType } from '@/contentTypes';
    import ioImg from '@/components/ioImg.vue';
    import axios from '@axios';
    import qs from 'qs';

    const $alert = useAlert();
    const $t = useI18n().t;

    const usersAnswers = ref<ContentType<'UserAnswer'>[]>();
    const isLoading = ref(true);

    const page = ref(1);
    const pageSize = ref(10);
    const pageCount = ref(0);

    const props = defineProps<{
        question: ContentType<'Question'>;
    }>();

    const $router = useRouter();
    const navigationDrawer = ref(true);

    const display = useDisplay();
    const totalVisible = ref(0);

    const isSmAndDown = computed(() => {
        const { smAndDown } = display;
        return smAndDown.value;
    });

    watch(isSmAndDown, () => {
        totalVisible.value = isSmAndDown.value ? 3 : 5;
    });

    watch(navigationDrawer, (value) => {
        if (!value) $router.push({ name: 'QuizzQuestions' });
    });

    onBeforeMount(() => {
        totalVisible.value = isSmAndDown.value ? 3 : 5;
    });

    const fetchUsersAnswers = async () => {
        try {
            const params = {
                filters: {
                    answer: {
                        question: {
                            id: {
                                $eq: props.question.id,
                            },
                        },
                    },
                },
                populate: ['user.picture', 'answer.question', 'answer.media'],
                sort: {
                    createdAt: 'asc',
                },
                pagination : {
                    page: page.value,
                    pageSize: pageSize.value
                }
            };
            const query = qs.stringify(params);
            const { data } = await axios.get(`/user-answers?${query}`);
            usersAnswers.value = data.data;
            pageCount.value = data.meta.pagination.pageCount;
            isLoading.value = false;
        } catch (error) {
            console.log(error);
            $alert.showAlert({
                    type: 'error',
                    text: `${$t('quizzes.alert.error')}`,
                });
        }
    };

    fetchUsersAnswers();
</script>
