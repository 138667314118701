<template>
    <Sidebar @isDesktopDrawer="updateLayoutClass" />
    <Navbar v-if="!hideNavbar && !isDesktopDrawerActive" />
    <v-main>
        <ioAlert />
        <!-- <v-btn @click="isSidebarOpen = !isSidebarOpen" class="tw-z-10" position="fixed" :location="'left top'" icon="mdi-menu" rounded=""></v-btn> -->
        <router-view />
    </v-main>
</template>

<script lang="ts" setup>
    import Sidebar from './Sidebar.vue';
    import Navbar from './Navbar.vue';
    import ioAlert from '@/components/ioAlert.vue';
    import { ref, watch } from 'vue';
    import { useRouter } from 'vue-router';
    import useNotifications from '@/composables/useNotifications';
    import axios from '@axios';

    const $router = useRouter();
    const hideNavbar = ref(false);

    const isDesktopDrawerActive = ref(true);

    const updateLayoutClass = (value: any) => {
        isDesktopDrawerActive.value = value;
    };

    watch($router.currentRoute, (val) => {
        if (val.name === 'Messages') {
            hideNavbar.value = true;
        } else {
            hideNavbar.value = false;
        }
    });

    async function init() {
        const token = await useNotifications().requestPermission();
        if (token) {
            await axios.post('/user-notification-tokens', { token });
        }
    }

    init();
</script>
