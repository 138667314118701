<template>
    <v-container
        v-if="!isLoading"
        class="!tw-mt-7 tw-flex tw-flex-col tw-gap-4 lg:!tw-mt-10">
        <v-row class="tw-items-center tw-justify-between">
            <v-col>
                <router-link
                    to=""
                    @click="goBack">
                    <v-icon class="tw-text-3xl tw-text-primary"> mdi-arrow-left </v-icon>
                </router-link>
            </v-col>
            <v-col>
                <h1
                    v-if="$route.name == 'CreateEvent'"
                    class="tw-text-center tw-text-2xl tw-font-semibold tw-text-secondary sm:tw-text-3xl">
                    {{ $t('events.form.create') }}
                </h1>
                <h1
                    v-if="$route.name == 'EditEvent'"
                    class="tw-text-center tw-text-2xl tw-font-semibold tw-text-secondary sm:tw-text-3xl">
                    {{ $t('events.form.edit') }}
                </h1>
            </v-col>
            <!-- Create/Edit Event (Desktop) -->
            <v-col class="tw-invisible tw-flex tw-justify-end sm:tw-visible">
                <v-btn
                    v-if="$route.name == 'CreateEvent'"
                    type="submit"
                    class="bg-primary text-white font-weight-semibold tw-w-1/2 tw-py-1.5 tw-text-end tw-text-base"
                    height="auto"
                    size="x-large"
                    @click="onSubmit">
                    {{ $t('events.form.submitCreate') }}
                </v-btn>
                <v-btn
                    v-if="$route.name == 'EditEvent'"
                    type="submit"
                    class="bg-primary text-white font-weight-semibold tw-w-1/2 tw-py-1.5 tw-text-end tw-text-base"
                    height="auto"
                    size="x-large"
                    @click="onSubmit">
                    {{ $t('events.form.submit') }}
                </v-btn></v-col
            >
        </v-row>
        <v-form
            ref="form"
            @submit.prevent="onSubmit"
            action="">
            <v-row>
                <!-- Image -->
                <v-col cols="12">
                    <io-img
                        class="tw-h-60 sm:tw-h-72"
                        format="thumbnail"
                        :media="event?.picture"
                        content-type="Media"
                        contain></io-img>
                    <v-file-input
                        :loading="isLoadingFile"
                        v-model="image"
                        class="custom-label tw-mt-4"
                        density="compact"
                        variant="underlined"
                        prepend-icon=""
                        :label="$t('events.form.selectPicture')"
                        clearable
                        show-size
                        :hide-details="true"
                        @update:model-value="onImageChanged"
                        accept="image/*">
                    </v-file-input>
                </v-col>
                <!-- Name -->
                <v-col
                    cols="12"
                    md="6">
                    <p class="tw-mb-2 tw-text-base tw-text-secondary">{{ $t('events.form.name') }}</p>
                    <v-text-field
                        :rules="[rules.required]"
                        clearable
                        bg-color="tw-bg-white"
                        v-model="event.name"
                        :label="$t('events.form.name')"
                        required></v-text-field>
                </v-col>
                <!-- Location -->
                <v-col
                    cols="12"
                    md="6">
                    <p class="tw-mb-2 tw-text-base tw-text-secondary">{{ $t('events.form.location') }}</p>
                    <v-text-field
                        clearable
                        bg-color="tw-bg-white"
                        v-model="event.location"
                        :rules="[rules.required]"
                        :label="$t('events.form.location')"
                        required></v-text-field>
                </v-col>
                <!-- Date -->
                <v-col
                    cols="6"
                    md="6">
                    <p class="tw-mb-2 tw-text-base tw-text-secondary">{{ $t('events.form.date') }}</p>
                    <v-text-field
                        clearable
                        bg-color="tw-bg-white"
                        v-model="event.date"
                        :rules="[rules.required]"
                        :label="$t('events.form.date')"
                        required
                        type="date"></v-text-field>
                </v-col>
                <!-- Time -->
                <v-col
                    cols="6"
                    md="6">
                    <p class="tw-mb-2 tw-text-base tw-text-secondary">{{ $t('events.form.time') }}</p>
                    <v-text-field
                        clearable
                        bg-color="tw-bg-white"
                        v-model="event.time"
                        :rules="[rules.required]"
                        :label="$t('events.form.time')"
                        required
                        type="time"></v-text-field>
                </v-col>
                <!-- Description -->
                <v-col
                    cols="12"
                    md="12">
                    <p class="tw-mb-2 tw-text-base tw-text-secondary">{{ $t('events.form.description') }}</p>
                    <v-textarea
                        variant="outlined"
                        clearable
                        v-model="event.description"
                        :rules="[rules.required]"
                        :label="$t('events.form.description')"
                        required></v-textarea>
                </v-col>
                <!-- Users -->
                <v-col
                    v-if="$route.name == 'CreateEvent' && me?.role?.name == 'Tutor'"
                    cols="12"
                    md="12">
                    <p class="tw-mb-2 tw-text-base tw-text-secondary">{{ $t('events.form.users') }}</p>
                    <v-select
                        item-color="primary"
                        bg-color="tw-bg-white"
                        clearable
                        chips
                        :label="$t('events.form.users')"
                        :items="me?.children"
                        item-title="username"
                        item-value="id"
                        v-model="users"
                        multiple
                        variant="outlined">
                    </v-select>
                </v-col>
            </v-row>
            <!-- Create/Edit Event (Mobile) -->
            <v-btn
                v-if="$route.name == 'CreateEvent'"
                type="submit"
                @click="onSubmit"
                class="bg-primary text-white font-weight-semibold tw-mx-auto tw-mt-3 tw-flex tw-w-full tw-items-center tw-justify-center tw-py-1.5 tw-text-base 3xs:tw-mb-2 sm:tw-hidden"
                height="auto"
                size="x-large">
                {{ $t('events.form.submitCreate') }}
            </v-btn>
            <v-btn
                v-if="$route.name == 'EditEvent'"
                type="submit"
                @click="onSubmit"
                class="bg-primary text-white font-weight-semibold tw-mx-auto tw-mt-3 tw-flex tw-w-full tw-items-center tw-justify-center tw-py-1.5 tw-text-base 3xs:tw-mb-2 sm:tw-hidden"
                height="auto"
                size="x-large">
                {{ $t('events.form.submit') }}
            </v-btn>
        </v-form>
    </v-container>
    <div
        v-if="isLoading"
        class="tw-fixed tw-inset-0 tw-z-10 tw-flex tw-h-full tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
</template>
<script setup lang="ts">
    import { ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { ContentType } from '@/contentTypes';
    import { uploadFiles } from '@/composables/upload';
    import { useAuthStore } from '@/store/auth';
    import ioImg from '@/components/ioImg.vue';
    import axios from '@axios';
    import qs from 'qs';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    import useRules from '@/composables/rules';
    import { useAlert } from '@/composables/useAlert';
    import { useI18n } from 'vue-i18n';

    const $alert = useAlert();
    const $t = useI18n().t;

    const rules = useRules();
    const form = ref<VForm>();

    const authStore = useAuthStore();
    const me = authStore.user;

    const $route = useRoute();
    const $router = useRouter();

    const event = ref<ContentType<'Event'>[]>([]);
    const users = ref<ContentType<'User'>[]>([]);
    const image = ref<File[]>([]);
    const isLoading = ref(true);
    const isLoadingFile = ref(false);

    if ($route.name == 'EditEvent') {
        const eventId = $route.params.id;
        const params = {
            populate: ['picture'],
        };
        const query = qs.stringify(params);
        axios
            .get(`/events/${eventId}?${query}`)
            .then((response) => {
                event.value = response.data.data;
                isLoading.value = false;
            })
            .catch((error) => {
                console.log(error);
                isLoading.value = false;

                $alert.showAlert({
                type: 'error',
                text: `${$t('events.alert.error')}`,
            });
            });
    } else {
        isLoading.value = false;
    }

    const createInvites = async (event) => {
        try {
            for (let user of users.value) {
                const params = {
                    data: {
                        event: event.id,
                        user: user,
                    },
                };
                await axios.post(`/event-attendances`, params);
            }
            isLoading.value = false;
            $router.push({ name: 'Events' });

            $alert.showAlert({
                type: 'success',
                text: `${$t('events.alert.success.create')}`,
            });
        } catch (error) {
            console.log(error);
            isLoading.value = false;

            $alert.showAlert({
                type: 'error',
                text: `${$t('events.alert.error')}`,
            });
        }
    };

    const updateEvent = async () => {
        try {
            if (event.value.time.length == 12) event.value.time = `${event.value.time}`;
            else event.value.time = `${event.value.time}:00.000`;

            const params = {
                data: {
                    ...event.value,
                },
            };
            await axios
                .put(`/events/${event.value.id}`, params)
                .then(() => {
                    isLoading.value = false;
                })
                .finally(() => {
                    $router.push({ name: 'Events' });

                    $alert.showAlert({
                        type: 'success',
                        text: `${$t('events.alert.success.update')}`,
                    });
                });
        } catch (error) {
            console.log(error);
            isLoading.value = false;

            $alert.showAlert({
                type: 'error',
                text: `${$t('events.alert.error')}`,
            });
        }
    };

    const createEvent = async () => {
        try {
            event.value.time = `${event.value.time}:00.000`;
            const params = {
                data: {
                    ...event.value,
                    createdByUser: me?.id,
                },
            };
            await axios.post(`/events`, params).then((response) => {
                let event = response.data.data;
                if (users.value == undefined) {
                    users.value = [];
                    isLoading.value = false;
                    $router.push({ name: 'Events' });

                    $alert.showAlert({
                        type: 'success',
                        text: `${$t('events.alert.success.create')}`,
                    });
                } else createInvites(event);
            });
        } catch (error) {
            console.log(error);
            isLoading.value = false;

            $alert.showAlert({
                type: 'error',
                text: `${$t('events.alert.error')}`,
            });
        }
    };

    const onSubmit = async () => {
        if (!form.value) return;
        form.value.validate();

        form.value.resetValidation();

        const { valid } = await form.value.validate();
        if (!valid) return;

        isLoading.value = true;
        try {
            if ($route.name == 'CreateEvent') {
                createEvent();
            } else {
                updateEvent();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onImageChanged = async () => {
        if (image?.value?.length == 0) return;
        isLoadingFile.value = true;
        await uploadFiles({
            files: image.value,
            field: 'picture',
        }).then((response) => {
            if (response.data.length > 0) event.value.picture = response.data[0];

            isLoadingFile.value = false;
        });
    };

    const goBack = () => {
        $router.go(-1);
    };
</script>
