<template>
    <v-container
        class="!tw-mt-10 tw-flex tw-h-full tw-flex-col tw-gap-4"
        v-if="isLoading == false">
        <!-- Header -->
        <v-row class="tw-flex-grow-0">
            <v-col
                v-if="user?.role?.name == 'Admin'"
                class="sm:tw-hidden"></v-col>
            <v-col
                xs="12"
                sm="9">
                <div class="tw-flex tw-items-center tw-justify-center tw-gap-x-4 sm:tw-justify-normal">
                    <h1 class="tw-text-2xl tw-font-semibold tw-text-secondary sm:tw-text-left sm:tw-text-3xl lg:tw-hidden">
                        {{ $t('users.profile') }}
                    </h1>
                    <h1 class="tw-hidden tw-text-2xl tw-font-semibold tw-text-secondary sm:tw-text-left sm:tw-text-3xl lg:tw-block">
                        {{ user.username }}
                    </h1>
                </div>
            </v-col>
            <!-- Edit User (Desktop) -->
            <v-col class="tw-hidden tw-text-center sm:tw-block">
                <v-btn
                    v-if="!isEditing && user?.role?.name == 'Admin'"
                    type="submit"
                    class="bg-primary text-white font-weight-semibold tw-mx-auto tw-w-1/2 tw-py-1.5 tw-text-base sm:tw-w-full"
                    height="auto"
                    size="x-large"
                    @click="isEditing = !isEditing">
                    {{ $t('users.edit') }}
                </v-btn>
                <v-btn
                    v-if="isEditing && user?.role?.name == 'Admin'"
                    type="submit"
                    class="bg-primary text-white font-weight-semibold tw-mx-auto tw-w-1/2 tw-py-1.5 tw-text-base sm:tw-w-full"
                    height="auto"
                    size="x-large"
                    @click="isEditing = !isEditing">
                    {{ $t('users.confirm') }}
                </v-btn></v-col
            >
            <!-- Edit User (Mobile) -->
            <v-col
                v-if="user?.role?.name == 'Admin'"
                @click="isEditing = !isEditing"
                align-self="center"
                class="tw-flex tw-justify-end tw-text-end sm:tw-hidden">
                <v-icon class="tw-text-3xl tw-text-secondary"> {{ isEditing ? 'mdi-check-circle' : 'mdi-pencil-circle' }} </v-icon>
            </v-col>
        </v-row>
        <!------------>
        <v-form
            v-model="valid"
            :disabled="!isEditing">
            <v-row class="tw-mt-4 tw-flex-col tw-items-center tw-gap-x-10 sm:tw-flex-row">
                <v-col cols="auto">
                    <io-img
                        class="tw-h-60 tw-w-60 tw-rounded-full sm:tw-h-72 sm:tw-w-72"
                        format="thumbnail"
                        :media="user?.picture"
                        content-type="Media"
                        cover></io-img>
                    <v-file-input
                        :loading="isLoadingFile"
                        v-if="user?.role?.name == 'Admin'"
                        v-model="image"
                        class="custom-label tw-mt-4 tw-w-60 sm:tw-w-72"
                        density="compact"
                        variant="underlined"
                        prepend-icon=""
                        :label="$t('users.changeProfilePicture')"
                        clearable
                        show-size
                        :hide-details="true"
                        @update:model-value="onImageChanged"
                        accept="image/*">
                    </v-file-input>
                </v-col>
                <v-col
                    cols="12"
                    sm="5">
                    <p
                        class="tw-mx-auto tw-mb-8 tw-w-52 tw-overflow-hidden tw-text-ellipsis tw-text-center tw-text-xl tw-font-semibold tw-text-secondary sm:tw-mx-0 sm:tw-text-left sm:tw-text-2xl lg:tw-hidden">
                        {{ user.username }}
                    </p>
                    <div v-if="user?.role?.name == 'Admin'">
                        <p class="tw-mb-4">
                            {{ $t('users.role') }}
                        </p>
                        <autocomplete-roles
                            density="compact"
                            v-model="role"></autocomplete-roles>
                    </div>
                    <div v-if="user?.role?.name == 'Admin' || user?.role?.name == 'Tutor'">
                        <p class="tw-mb-4">
                            {{ $t('users.email') }}
                        </p>
                        <v-text-field
                            clearable
                            :label="$t('users.email')"
                            variant="outlined"
                            v-model="user.email"
                            bg-color="tw-white"
                            class="tw-mb-4"></v-text-field>
                    </div>
                    <v-card
                        v-if="user?.role?.name == 'Utente'"
                        elevation="2"
                        class="mx-auto tw-h-24 tw-py-1"
                        :title="user?.parent?.username"
                        :subtitle="$t('role.tutor')">
                        <template v-slot:prepend>
                            <v-avatar size="65">
                                <io-img
                                    class="2xl:tw-h-16 2xl:tw-w-16"
                                    format="thumbnail"
                                    :media="user?.parent?.picture"
                                    content-type="Media"
                                    width="65"
                                    height="65"
                                    cover></io-img>
                            </v-avatar>
                        </template>
                    </v-card>
                    <router-link :to="{ name: 'ChangePassword' }">
                        <v-card
                            v-if="user?.role?.name == 'Utente' || user?.role?.name == 'Tutor'"
                            elevation="2"
                            :title="$t('users.changePassword')"
                            class="mx-auto tw-mt-8 tw-h-20 tw-py-4">
                            <template v-slot:prepend>
                                <v-icon class="tw-text-primary">mdi-lock</v-icon>
                            </template>
                            <template v-slot:append>
                                <v-icon class="tw-text-primary">mdi-arrow-right</v-icon>
                            </template>
                        </v-card>
                    </router-link>
                </v-col>
            </v-row>
        </v-form>
        <!-- Logout -->
        <v-row class="tw-mb-10 tw-flex-col tw-justify-end">
            <v-btn
                type="submit"
                class="bg-primary text-white font-weight-semibold tw-mx-auto tw-mt-72 tw-h-12 tw-w-full tw-py-1.5 tw-text-base 3xs:tw-mt-64 2xs:tw-mt-52 xs:tw-mt-40 lg:tw-hidden"
                height="auto"
                size="x-large"
                @click="logout">
                {{ $t('users.logout') }}
            </v-btn>
        </v-row>
    </v-container>
    <div
        v-if="isLoading"
        class="tw-flex tw-h-full tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
</template>
<script setup lang="ts">
    import ioImg from '@/components/ioImg.vue';
    import AutocompleteRoles from '@/views/Users/AutocompleteRoles.vue';
    import { useAuthStore } from '@/store/auth';
    import { useSocket } from '@/composables/useSocket';
    import useNotifications from '@/composables/useNotifications';
    import qs from 'qs';
    import axios from '@axios';
    import { ref, watch } from 'vue';
    import { uploadFiles } from '@/composables/upload';
    import { useRouter } from 'vue-router';
    import { useAlert } from '@/composables/useAlert';
    import { useI18n } from 'vue-i18n';

    const $socket = useSocket();
    const $alert = useAlert();
    const $t = useI18n().t;

    const $router = useRouter();

    const authStore = useAuthStore();
    const user = authStore.user;
    const _notifications = useNotifications();

    const role = ref(user?.role?.name);
    const isEditing = ref(false);

    const isLoading = ref(false);
    const isLoadingFile = ref(false);
    const image = ref<File[]>([]);

    watch(isEditing, (value) => {
        if (!value) {
            updateUser();
        }
    });

    const onImageChanged = async () => {
        if (image?.value?.length == 0) return;
        isLoadingFile.value = true;
        await uploadFiles({
            files: image.value,
            refId: user?.value?.id,
            field: 'picture',
        }).then((response) => {
            isLoadingFile.value = false;
            if (response.data.length > 0) user.picture = response.data[0];
        });
    };

    const updateUser = async () => {
        isLoading.value = true;
        try {
            await axios
                .put(`/users/${user?.id}`, {
                    data: {
                        ...user,
                        role: role?.value == 'Admin' ? 3 : role?.value == 'Utente' ? 5 : 4,
                        email: user?.email,
                    },
                })
                .then((response) => {
                    useAuthStore().setUser({ ...user, ...response.data });

                    isLoading.value = false;
                    $alert.showAlert({
                        type: 'success',
                        text: `${$t('users.alert.success.update')}`,
                    });
                });
        } catch (error) {
            console.log(error);
            isLoading.value = false;

            $alert.showAlert({
                type: 'error',
                text: `${$t('users.alert.error')}`,
            });
        }
    };

    const deleteNotificationsToken = async () => {
        let notificationsToken;

        await _notifications.getToken().then((token) => {
            notificationsToken = token;
        });

        const params = {
            filters: {
                token: {
                    $eq: notificationsToken,
                },
            },
        };

        const query = qs.stringify(params);

        await axios.get(`/user-notification-tokens?${query}`).then((response) => {
            if (response.data.data.length > 0) {
                response.data.data.forEach(async (item: any) => {
                    await axios.delete(`/user-notification-tokens/${item.id}`);
                });
            }
        });
    };

    async function logout() {
        await deleteNotificationsToken();

        $socket.emit('disconnected');

        authStore.$reset();

        $router.push({ name: 'Login' });
    }
</script>
