<template>
    <v-container
        class="!tw-mt-10 tw-flex tw-flex-col tw-gap-4"
        fluid>
        <!-- Header -->
        <v-row>
            <v-col>
                <h1 class="tw-text-2xl tw-font-semibold tw-text-secondary sm:tw-text-left sm:tw-text-3xl">
                    {{ $t('chat.title') }}
                </h1>
            </v-col>
            <!-- Start Chat (Mobile) -->
            <v-col class="tw-text-end sm:tw-hidden">
                <router-link :to="{ name: 'StartChat' }">
                    <v-icon class="tw-text-3xl tw-text-secondary"> mdi-plus-circle </v-icon>
                </router-link>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col
                sm="10"
                md="11">
                <v-text-field
                    v-model="search"
                    clearable
                    :label="$t('chat.search')"
                    variant="outlined"
                    bg-color="tw-white"></v-text-field>
            </v-col>
            <!-- Start Chat (Desktop) -->
            <v-col
                align-self="center"
                class="tw-hidden tw-text-end sm:tw-block">
                <router-link :to="{ name: 'StartChat' }">
                    <v-icon class="tw-text-4xl tw-text-secondary"> mdi-plus-circle </v-icon>
                </router-link>
            </v-col>
        </v-row>
        <!------------>
        <router-link
            v-for="(chatRoom, index) of chatRooms"
            :key="chatRoom.id"
            v-intersect="index === chatRooms.length - 1 ? onIntersect : ''"
            to=""
            @click.prevent="joinChatRoom(chatRoom.id)">
            <v-card
                elevation="0"
                class="mx-auto tw-px-0"
                :title="chatRoom.users.find((usr) => usr.id !== user.id)?.username">
                <template v-slot:subtitle>
                    <div>
                        <p
                            v-if="chatRoom.chatMessages[chatRoom.chatMessages.length - 1]?.chatRead?.wasRead == true || chatRoom.chatMessages[chatRoom.chatMessages.length - 1]?.user?.id == user?.id"
                            class="tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap tw-font-light tw-text-secondary">
                            {{ chatRoom.chatMessages[chatRoom.chatMessages.length - 1]?.message }}
                        </p>
                        <p
                            v-else
                            class="tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap tw-font-semibold tw-text-secondary">
                            {{ chatRoom.chatMessages[chatRoom.chatMessages.length - 1]?.message }}
                        </p>
                    </div>
                </template>
                <template v-slot:prepend>
                    <v-avatar size="65">
                        <io-img
                            class="2xl:tw-h-16 2xl:tw-w-16"
                            format="thumbnail"
                            :media="chatRoom.users.find((usr) => usr.id !== user.id)?.picture"
                            content-type="Media"
                            width="65"
                            height="65"
                            cover></io-img>
                    </v-avatar>
                </template>
                <template v-slot:append>
                    <p class="tw-font-light tw-text-secondary">
                        {{ new Date(chatRoom.chatMessages[chatRoom.chatMessages.length - 1]?.createdAt).toLocaleTimeString('pt-PT', { hour: '2-digit', minute: '2-digit' }) }}
                    </p>
                </template>
            </v-card>
        </router-link>
        <div
            v-if="chatRooms.length == 0 && !isLoading"
            class="tw-mt-4 tw-flex tw-h-full tw-items-center tw-justify-center tw-gap-x-1 tw-font-semibold">
            <p>{{ $t('chat.chatNotFound') }}</p>
            <v-icon class="tw-text-primary">mdi-alert-circle</v-icon>
        </div>
    </v-container>
    <div
        v-if="isLoading"
        class="tw-flex tw-h-full tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
</template>
<script setup lang="ts">
    import ioImg from '@/components/ioImg.vue';
    import axios from '@axios';
    import qs from 'qs';
    import { useAuthStore } from '@/store/auth';
    import { ContentType } from '../../contentTypes';
    import { ref, watch, computed, onBeforeMount } from 'vue';
    import { useSocket } from '@/composables/useSocket';
    import { useRouter } from 'vue-router';
    import { useDisplay } from 'vuetify';

    const $socket = useSocket();

    const useStore = useAuthStore();
    const user = useStore.user;

    const page = ref(1);
    const pageSize = ref(25);
    const pageCount = ref(0);

    const $router = useRouter();

    const search = ref('');
    const chatRooms = ref<ContentType<'ChatRoom'>[]>([]);
    const isLoading = ref(true);

    const hasMoreChatRooms = ref(true);

    const display = useDisplay();
    const totalVisible = ref(0);

    const isSmAndDown = computed(() => {
        const { smAndDown } = display;
        return smAndDown.value;
    });

    watch(isSmAndDown, () => {
        totalVisible.value = isSmAndDown.value ? 3 : 5;
    });

    watch(search, () => {
        fetchChatRooms();
    });

    onBeforeMount(() => {
        totalVisible.value = isSmAndDown.value ? 3 : 5;
    });

    //get chat rooms
    const fetchChatRooms = async () => {
        const params = {
            filters: {
                users: [user.id],
                chatMessages: {
                    id: {
                        $notNull: true,
                    },
                },
                blocked: false,
            },
            pagination: {
                page: page.value,
                pageSize: pageSize.value,
            },
            populate: ['users.picture', 'chatMessages.chatRead', 'chatMessages.user'],
        };
        const query = qs.stringify(params);
        const { data } = await axios.get(`/chat-rooms?${query}`);

        if (data.meta.pagination.pageCount == page.value) {
            hasMoreChatRooms.value = false;
        }

        chatRooms.value = [...chatRooms.value, ...data.data];

        pageCount.value = data.meta.pagination.pageCount;

        if (search.value) {
            chatRooms.value = chatRooms.value.filter((chatRoom) => {
                return chatRoom.users.some((usr) => usr.id !== user.id && usr.username.toLowerCase().includes(search.value.toLowerCase()));
            });
        }
        isLoading.value = false;
    };

    fetchChatRooms();

    //join chat room
    const joinChatRoom = async (chatRoomId: number) => {
        $socket.emit('join', { user: user.id, room: chatRoomId });
        $router.push({ name: 'Messages', params: { id: chatRoomId } });
    };

    // Handle scroll, to load more chats
    const onIntersect = (entries: IntersectionObserverEntry[]) => {
        if (entries && hasMoreChatRooms.value) {
            page.value++;
            fetchChatRooms();
        }
    };
</script>
