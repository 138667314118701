<template>
    <v-app>
        <InstallationHelp />
        <PromptUpdate />
        <PromptInstall />
        <router-view />
    </v-app>
</template>

<script setup lang="ts">
    import { useAuth } from '@/composables/useAuth';
    import { useMessage } from '@/composables/useMessage';
    import { useAuthStore } from '@/store/auth';
    import InstallationHelp from './components/InstallationHelp.vue';
    import PromptUpdate from './components/PromptUpdate.vue';
    import PromptInstall from './components/PromptInstall.vue';

    const authStore = useAuthStore();
    const userId = authStore?.user?.id;

    useAuth()
        .checkAuth()
        .then(() => {
            if (userId) useMessage().fetchUnreadMessages(userId);
        });
</script>
