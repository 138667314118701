// Utilities
import { defineStore } from 'pinia';

export const useAppStore = defineStore('app', {
    state: () => ({
        _isSidebarOpen: false,
        _isInstallerInstructionsVisible: true,
    }),
    getters: {
        isSidebarOpen(): boolean {
            return this._isSidebarOpen;
        },
        isInstallerInstructionsVisible(): boolean {
            return this._isInstallerInstructionsVisible;
        }
    },
    actions: {
        setIsSidebarOpen(value: boolean) {
            this._isSidebarOpen = value;
        },
        setIsInstallerInstructionsVisible(value: boolean) {
            this._isInstallerInstructionsVisible = value;
        }
    },
    persist: true,
});
